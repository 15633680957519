import { get_token} from "@/router";

export default {
    data() {
        return ({
            notifications: [],
            user_id: null,
            isLoading: false,
            token_exists: false
        });
    },
    mounted() {
        this.token_exists = get_token();
        this.user_id = this.get_user_id();
        if (this.user_id && this.token_exists) {
            this.fetchNotifications();
        }
    },
    methods: {
        get_user_id() {
            const user_id = localStorage.getItem('user_id') || '';
            return user_id;
        },
        getToken() {
            return get_token();
        },
        fetchNotifications() {
            this.isLoading = true;
            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_notification/`,
                method: 'GET',
                headers: {
                    'Authorization': `Token ${this.getToken()}`,
                    'Content-Type': 'application/json'
                },

                success: (response) => {
                    var status = response.status
                    if (status == "success") {
                        this.notifications = response.notifications
                    }

                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        }
    }
};