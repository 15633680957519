import { get_token } from "@/router";
import Swal from 'sweetalert2';

export default {

    name: "GroupFeedCommentModal",

    props: {
        title: {
            type: String,
            default: "Modal Title",
        },
        feed_data: {
            type: Object,
        },
    },

    data() {
        var data = {
            comments_data: {},
            user_id:null,
            isZoomed: false,
            isFullscreen: false,
            comment_count: 0,
            editing_comment_text: "",
            editing_comment_id: null,
            expandedFeedComments: [],
        };

        return data;
    },

    mounted() {
        this.user_id = this.get_user_id();
        this.show_modal();
        this.get_comments();
    },


    methods: {
        toggleZoom() {
            this.isZoomed = !this.isZoomed;
          },
          toggleFullscreen() {
            const image = this.$refs.image[0];
            
            // Check if fullscreen is already active
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
              // Request fullscreen for the image element (using the correct method for various browsers)
              if (image.requestFullscreen) {
                image.requestFullscreen();
              } else if (image.mozRequestFullScreen) { /* Firefox */
                image.mozRequestFullScreen();
              } else if (image.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                image.webkitRequestFullscreen();
              } else if (image.msRequestFullscreen) { /* IE/Edge */
                image.msRequestFullscreen();
              }
            } else {
              // Exit fullscreen
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
              }
            }
          },

        get_user_id() {
            const user_id = localStorage.getItem('user_id') || '';
            return user_id;
        },
        getToken() {
            return get_token();
        },
        show_modal() {
            $("#comment_modal").modal("show");
        },

        hide_modal() {
            $("#comment_modal").modal("hide");
            this.$emit('hide_comment_comment')
        },

        submitComment() {

            const commentText = $("#comment_text_area").val().trim();
        
            if (commentText === "") {
                Swal.fire({
                    icon: 'error',
                    title: 'Please write a comment before submitting.',
                    timer: 2000,
                    showConfirmButton: false,
                });
                return;
            }
        
            const requestData = {
                group_feed_id: this.feed_data.id,
                comment_text: commentText,
            };
        
            $.ajax({
                type: "POST",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/add_feed_comment/`,
                contentType: "application/json",
                headers: {
                    'Authorization': `Token ${this.getToken()}`,
                },
                data: JSON.stringify(requestData),
                success: (response) => {
                    if (response.status === "success") {
                        const response_data = response.data;
                        this.comments_data.push(response_data.comment_data);
                        this.comment_count +=1;

                        $("#comment_text_area").val("");
                        return;
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Could not add comment',
                            text: 'Please try again later.',
                            timer: 3000,
                            showConfirmButton: false,
                        });
                    }
                },
            });
        },

        get_comments() {
            var request_data = {
                "group_feed_id": this.feed_data.id
            }

            $.ajax({
                type: "GET",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/retrieve_group_feed_comment/`,
                contentType: "application/json",
                headers: {
                    'Authorization': `Token ${this.getToken()}`,
                },
                data: request_data,
                success: (response) => {
                    if (response.status === "success") {
                        const response_data = response.data;
                        this.comments_data = response_data;
                        this.comment_count = this.comments_data.length;
                    }

                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        },

        edit_comment(comment_data) {
            this.editing_comment_id = comment_data.id;
            this.editing_comment_text = comment_data.comment_text;
            
        },

        save_edit_comment(comment_id) {
            const data = {
                comment_id: comment_id,
                comment_text: this.editing_comment_text
            };
            $.ajax({
                type: 'POST',
                url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/update_group_feed_comment/`,
                headers: {
                    'Authorization': `Token ${this.getToken()}`,},
                contentType: 'application/json',
                data: JSON.stringify(data),
                success: (response) => {
                    if (response.status === "success") {
                        const comment = this.comments_data.find(comment => comment.id === comment_id);
                        comment.comment_text = this.editing_comment_text;
                        this.editing_comment_id = null;
                        this.editing_comment_text = "";
                    } 
                },
            });
        },

        delete_comment(comment_id) {
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes,delete it'
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        type: "DELETE",
                        url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/delete_group_feed_comment/`,
                        data: JSON.stringify({ comment_id: comment_id }),
                        contentType: 'application/json',
                        headers: {
                            'Authorization': `Token ${this.getToken()}`,},
                        success: (response) => {
                            if (response.status === "success") {
                                Swal.fire({
                                    title: 'Deleted!',
                                    text: 'The comment has been deleted.',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1000
                                })
                                .then(() => {
                                    this.get_comments();
                                    this.comment_count -= 1;

                                });
                            }
                        },
                        
                    });
                }
            });
        },
        readMore(commentsId) {
            const index = this.expandedFeedComments.indexOf(commentsId);
            if (index === -1) {
              this.expandedFeedComments.push(commentsId);
            } else {
              this.expandedFeedComments.splice(index, 1);
            }
          },
        }
    }