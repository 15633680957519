<div class="floating-dropdown">
  <!-- Floating Button -->
  <button class="btn btn-primary floating-btn shadow" @click="toggleDropdown">
    <i class='bx bxs-comment-add'></i>
  </button>
  <!-- Dropdown Menu -->
  <div v-if="isDropdownVisible" class="dropdown-menu show rounded-5 shadow border-0 dropdown-float" >
    <a v-if="!isGroupPage" class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#feed_modal"> <i
        class='bx bxs-report fs-6'></i> Create Feed</a>
    <a v-if="!isGroupPage"  class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#voice_modal"><i
        class='bx bxs-message-dots fs-6'></i> Create Voice</a>
    <a  v-if="!isPortalPage && !isGroupPage" class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#create_community_asset_modal"><i
        class='bx bxs-message-dots fs-6'></i> Create Community Asset</a>
        <a v-if="!isHomePage && !isPortalPage" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#group_feed_modal">
          <i class='bx bxs-message-dots fs-6'></i> Create feed
      </a>
      <a v-if="!isHomePage && !isPortalPage" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#group_voice_modal">
        <i class='bx bxs-message-dots fs-6'></i> Create voice
    </a>
    <a  v-if="!isPortalPage " class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#group_modal"><i
        class='bx bxs-message-dots fs-6'></i> Create Group</a>
    <a v-if="!isGroupPage" class="dropdown-item " href="#" data-bs-toggle="modal" data-bs-target="#portal_modal"><i
        class='bx bxs-message-dots fs-6'></i> Create Portal</a>
    <a  v-if="!isHomePage && !isGroupPage"  class="dropdown-item " href="#" data-bs-toggle="modal" data-bs-target="#segment_modal"><i
        class='bx bxs-message-dots fs-6'></i> Create Segment</a>
    <a v-if="!isHomePage && !isPortalPage && created_by_id==user_details.user_id" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#group_member_modal">
      <i class='bx bxs-message-dots fs-6'></i> Add group member
  </a>
 
  </div>
</div>
<!-- Group feed modal -->
<div class="modal fade" id="group_feed_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create your feed</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="create_group_feed">
        <div class="modal-body">

          <div class="mb-3">
            <label for="report_name" class="form-label text-xs fw-500">Feed Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="group_report_name" placeholder="Enter feed name"
              v-model="group_report_name">
              <p v-if="errors.report_name" class="text-danger text-xs">{{ errors.report_name}}</p>
          </div>
          <QuillEditor theme="snow" v-model="feed_report_description" ref="quillFeedReportEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i> Add feed content
                </label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ fileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
              </div>
            
              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i> Upload Audio
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
            
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button" class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i> Record
                  </button>
                  <button type="button" class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> Stop
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div>
                              
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Group Voice modal -->
<div class="modal fade" id="group_voice_modal" tabindex="-1" aria-labelledby="groupVoiceModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="groupVoiceModalLabel">Create a Group Voice</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="create_group_voice">
        <div class="modal-body">
          <div class="mb-3">
            <label for="voice_name" class="form-label text-xs fw-500">Voice Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="voice_name" placeholder="Enter voice name"
              v-model="group_voice_name">
              <p v-if="errors.voice_name" class="text-danger text-xs">{{ errors.voice_name }}</p>
          </div>
          <div class="mb-3">
            <label for="voice_description" class="form-label text-xs fw-500">Description <span class="text-danger">*</span></label>
            <QuillEditor theme="snow" v-model="group_voice_description" ref="quillVoiceDescriptionRef" class="quill-editor"
              placeholder="Enter your description here..." toolbar="full" />
              <p v-if="errors.voice_description" class="text-danger text-xs">{{ errors.voice_description }}</p>
              <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
                <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                  <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                    <i class='bx bx-image-add file-upload-icon'></i> Add voice content
                  </label>
                  <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                  <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                    <span class="fw-500">File name:</span> {{ fileName }}
                  </div>
                  <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
                </div>
              
                <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                  <label for="audioInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                    <i class='bx bx-music'></i> Upload Audio
                  </label>
                  <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                  <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
                </div>
              
                <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                  <div class="d-flex flex-row align-items-center mb-3">
                    <button type="button" class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                      <i class='bx bx-microphone'></i> Record
                    </button>
                    <button type="button" class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                      <i class='bx bx-stop'></i> Stop
                    </button>
                  </div>
                  <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                    <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                  </div>
                </div>
              </div>
             
            </div>  
        </div>

        <!-- Modal Footer -->
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- Feed Modal -->
<div class="modal fade" id="feed_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create your feed</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="submit_form">
        <div class="modal-body">

          <div class="mb-3">
            <label for="report_name" class="form-label text-xs fw-500">Feed Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="report_name" placeholder="Enter feed name"
              v-model="report_name">
              <p v-if="errors.report_name" class="text-danger text-xs">{{ errors.report_name }}</p>
          </div>
          <div class="d-flex flex-row justify-content-between mx-1">
            <div>
              <label for="portals" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a portal :
              </label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end w-100"
                v-model="selectedPortalId" @change="onPortalChange" id="portals">
                <option value="" selected hidden>
                  * Tag a portal
                </option>
                <option v-for="portal in portals" :value="portal.id" :key="portal.id">{{portal.name }}</option>
              </select>
              <p v-if="errors.portal" class="text-danger text-xs">{{ errors.portal }}</p>
            </div>

            <div>
              <label for="segment" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a segment :
              </label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end w-100"
                v-if="topics.length > 0" v-model="selectedTopicId" id="topic">
                <option value="" selected hidden>
                  <span class="text-danger">*</span> Tag a segment
                </option>
                <option v-for="topic in topics" :value="topic.id" :key="topic.id">
                  {{ topic.name }}
                </option>
              </select>
              <p v-if="errors.segment" class="text-danger text-xs">{{ errors.segment }}</p>
            </div>
          </div>
          <QuillEditor theme="snow" v-model="report_description" ref="quillReportEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start border-bottom">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i> Add feed content
                </label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ fileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
              </div>
            
              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i> Upload Audio
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
            
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button" class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i> Record
                  </button>
                  <button type="button" class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> Stop
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div>
            
            
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
              Public <br>
              <span class="text-xxs text-secondary">Is the feed public ?</span>
            </label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="private">
              Private <br>
              <span class="text-xxs text-secondary">Is the feed private ?</span>
            </label>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Voice Modal -->
<div class="modal fade" id="voice_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Create your voice</h5>
        <button type="button" class="btn-close  text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createHub">
        <div class="modal-body">

          <div class="mb-3">
            <label for="name" class="form-label text-xs fw-500">Voice Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="name" placeholder="Enter voice name"
              v-model="name">
              <p v-if="errors.name" class="text-danger text-xs">{{ errors.name }}</p>
          </div>
          <div class="d-flex flex-row justify-content-between mx-1">
            <div>
              <label for="portals" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a portal :
              </label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end w-100"
                v-model="selectedPortalId" @change="onPortalChange" id="portals">
                <option value="" selected hidden>
                  * Tag a portal
                </option>
                <option v-for="portal in portals" :value="portal.id" :key="portal.id">{{portal.name }}</option>
              </select>
              <p v-if="errors.portal" class="text-danger text-xs">{{ errors.portal }}</p>
            </div>

            <div>
              <label for="segment" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a segment :
              </label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end w-100"
                v-if="topics.length > 0" v-model="selectedTopicId" id="topic">
                <option value="" selected hidden>
                  <span class="text-danger">*</span> Tag a segment
                </option>
                <option v-for="topic in topics" :value="topic.id" :key="topic.id">
                  {{ topic.name }}
                </option>
              </select>
              <p v-if="errors.segment" class="text-danger text-xs">{{ errors.segment }}</p>
            </div>

          </div>
          <QuillEditor theme="snow" v-model="description" ref="quillHubRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i> Add voice content
                </label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ fileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
              </div>
            
              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i> Upload Audio
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button" class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i> Record
                  </button>
                  <button type="button" class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> Stop
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div>  
          </div>  
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Portal Modal -->
<div class="modal fade" id="portal_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Portal</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createPortal">
        <div class="modal-body">

          <div class="mb-3">
            <label for="portal_name" class="form-label text-xs fw-500">Portal Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="portal_name" placeholder="Enter portal name"
              v-model="portal_name">
              <p v-if="errors.portal_name" class="text-danger text-xs">{{ errors.portal_name }}</p>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
              Public <br>
              <span class="text-xxs text-secondary">Is the portal public ?</span>
            </label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="private">
              Private <br>
              <span class="text-xxs text-secondary">Is the portal private ?</span>
            </label>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Group Modal -->
<div class="modal fade" id="group_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Group</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createGroup">
        <div class="modal-body">

          <div class="mb-3">
            <label for="group_name" class="form-label text-xs fw-500">Group Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="group_name" placeholder="Enter group name"
              v-model="group_name">
              <p v-if="errors.group_name" class="text-danger text-xs">{{ errors.group_name }}</p>
          </div>
          <QuillEditor theme="snow" v-model="description" ref="quillGroupEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
              Public <br>
              <span class="text-xxs text-secondary">Is the group public ?</span>
            </label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="private">
              Private <br>
              <span class="text-xxs text-secondary">Is the group private ?</span>
            </label>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Group member modal -->
<div class="modal fade" id="group_member_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Add a group member</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="add_group_member">
        <div class="modal-body">
          <table class="table table-striped text-xs">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="(user, index) in users" :key="user.id" v-show="user.role.role === 'customer'">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ user.first_name }}</td>
                <td>{{ user.last_name }}</td>
                <td>
                  <div class="form-check">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      :id="'member_check_' + user.id" 
                      :value="user.id" 
                      v-model="selectedUserIds"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Add member</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Segment Modal -->
<div class="modal fade" id="segment_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Segment</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createSegment">
        <div class="modal-body">

          <div class="mb-3">
            <label for="topic_name" class="form-label text-xs fw-500">Segment Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="topic_name" placeholder="Enter topic name"
              v-model="topic_name">
              <p v-if="errors.topic_name" class="text-danger text-xs">{{ errors.topic_name }}</p>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Community Asset Modal -->
<div class="modal fade" id="create_community_asset_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create Community Assets</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createcommunity_asset">
        <div class="modal-body">
 
          <div class="mb-3">
            <label for="asset_name" class="form-label text-xs fw-500">Community Asset Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="asset_name" placeholder="Enter community asset name"
              v-model="asset_name">
              <p v-if="errors.asset_name" class="text-danger text-xs">{{ errors.asset_name }}</p>
          </div>
          <div class="mb-3">
            <label for="asset_name" class="form-label text-xs fw-500">Community Asset Location <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="location" placeholder="Enter community asset name"
              v-model="location" :class="{'is-invalid': errors.location}">
              <p v-if="errors.location" class="text-danger text-xs">{{ errors.location }}</p>
          </div>
          <div class="mb-3">
            <label for="category_id" class="form-label text-xs fw-500">Select a Category<span class="text-danger">*</span></label>
            <select class="form-select rounded-5 text-xs" id="category_id" v-model="category_id">
              <option selected hidden>Select a Category</option>
              <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
            </select>
            <p v-if="errors.category_id" class="text-danger text-xs">{{ errors.category_id }}</p>
          </div>
          <div class="mb-3">
            <label for="asset_name" class="form-label text-xs fw-500">Subscription fee</label>
            <input type="text" class="form-control rounded-5 text-xs" id="subscription_fee" placeholder="R0"
              v-model="subscription_fee">
              <p v-if="errors.subscription_fee" class="text-danger text-xs">{{ errors.subscription_fee }}</p>
          </div>
          <QuillEditor theme="snow" v-model="description" ref="quillAssetEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
          <div class="mt-2 mx-1">
            <div class="d-flex flex-row justify-content-between">
              <div class="file-upload">
                <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light"><i
                    class='bx bx-image-add file-upload-icon'></i> Add community content</label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
              </div>
              <div v-if="fileName" class="text-xs mt-2 text-secondary text-danger fst-italic"><span class="fw-500">File
                  name:</span>{{ fileName }}</div>
            </div>
            <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
          </div>
 
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>