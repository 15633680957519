<section class="image-background">
    <div class="form-bg">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-md-4 col-sm-6">
                  <div class="form-container rounded-5 shadow p-5 top-20">
                      <h3 class="title text-center">Reset password</h3>        
                      <p class="d-block mb-3 text-center text-secondary">Enter your e-mail address to obtain a new password</p>
                      <form>
                        <div class="mb-3">
                          <label for="email" class="form-label text-xs fw-500">Email<span class="text-danger">*</span></label>
                          <input type="email" class="form-control rounded-5 text-xs p-2" placeholder="example@gmail.com" id="email" v-model="email" required>
                        </div>                  
                          <button type="button" class="btn btn-primary rounded-5 shadow-sm w-100 mt-3 mb-1">Send email</button>
                          <p class="text-start text-xs">Didn't get the email?<router-link to="reset_password" class="text-info fw-500">Resend</router-link></p>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </section>
  
  