<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>

    <!-- customer profile -->
    <div class="card mt-3 p-4 rounded-top-5 shadow scrollable-form">
      <form @submit.prevent="saveProfile" id="profileForm" novalidate class="text-xs">
        <div class="row mb-3">
          <div class="col-lg-6">
            <label for="first_name" ref="first_name" class="form-label fw-500">First Name<span class="text-danger"> *</span></label>
            <input type="text" class="form-control text-xs rounded-5" id="first_name" v-model="first_name"
              placeholder="First name" :class="{'is-invalid': errors.first_name}">
            <p v-if="errors.first_name" class="text-danger text-xs">{{ errors.first_name }}</p>
          </div>
          <div class="col-lg-6">
            <label for="last_name" ref="last_name" class="form-label fw-500">Last Name<span class="text-danger"> *</span></label>
            <input type="text" class="form-control text-xs rounded-5" id="last_name" v-model="last_name"
              placeholder="Last name" :class="{ 'is-invalid': errors.last_name }">
            <p v-if="errors.last_name" class="text-danger text-xs">{{ errors.last_name }}</p>
          </div>
        </div>
        <div class="mb-3">
          <label for="email" ref="email" class="form-label fw-500">Email<span class="text-danger"> *</span></label>
          <input type="email" class="form-control text-xs rounded-5" id="email" readonly v-model="email"
            placeholder="Email" :class="{ 'is-invalid': errors.email }">
          <p v-if="errors.email" class="text-danger text-xs">{{ errors.email }}</p>
        </div>
        <div class="mb-3">
          <label for="cv" ref="cv" class="form-label fw-500">CV<span class="text-danger"> *</span></label>
          <div class="d-flex align-items-center">
            <input
              type="file"
              class="form-control text-xs rounded-5"
              id="cv"
              @change="handleFileUpload"
              accept=".pdf, .doc, .docx"
            />
            <div v-if="cv_url" class="ms-3">
              <button type="button" class="btn btn-outline-primary text-xs" @click="previewDocument">
                <i class="bi bi-eye"></i> Preview
              </button>
            </div>
          </div>
          <p v-if="errors.cv" class="text-danger text-xs">{{ errors.cv }}</p>
          <div v-if="file_name" class="text-danger text-sm mt-2">{{ file_name }}</div>
        </div>

        <div v-if="showPreview" class="modal-overlay" @click="closeModal">
          <div class="modal-content" @click.stop>
            <span class="close-button" @click="closeModal">&times;</span>
            <div v-if="local_file_url" class="mt-4">
              <iframe v-if="isLocalFile" :src="local_file_url" type="application/pdf"
              frameborder="0"></iframe>
            </div>
            <div v-if="cv_url" class="mt-4">
              <iframe v-if="!isLocalFile"  
                :src="' https://docs.google.com/viewer?url='+ encodeURIComponent(cv_url) + '&embedded=true'" 
                frameborder="0"></iframe>
              </div>
            <div v-else>
              <p>Preview not available for this file type.</p>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="date_of_birth" ref="date_of_birth" class="form-label fw-500">Date of Birth<span class="text-danger"> *</span></label>
          <input type="date" class="form-control text-xs rounded-5" id="date_of_birth" v-model="date_of_birth"
            placeholder="Date of birth" :class="{ 'is-invalid': errors.date_of_birth }" required>
          <p v-if="errors.date_of_birth" class="text-danger text-xs">{{ errors.date_of_birth }}</p>
        </div>
        <div class="mb-3">
          <label for="phone_number" ref="phone_number" class="form-label fw-500">Phone Number<span class="text-danger"> *</span></label>
          <input type="text" class="form-control text-xs rounded-5" id="phone_number" v-model="phone_number"
            placeholder="Phone number" :class="{ 'is-invalid': errors.phone_number }" required>
          <p v-if="errors.phone_number" class="text-danger text-xs">{{ errors.phone_number }}</p>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <label for="race_id" ref="race_id" class="form-label fw-500">Race<span class="text-danger"> *</span></label>
            <select class="form-control text-xs rounded-5" id="race_id" v-model="race_id"
              :class="{ 'is-invalid': errors.race_id }">
              <option selected hidden value="">Select Race</option>
              <option v-for="race in races" :value="race.id" :key="race.id">
                {{ race.race }}
              </option>
            </select>
            <p v-if="errors.race_id" class="text-danger text-xs">{{ errors.race_id }}</p>
          </div>
          <div class="col-lg-6">
            <label for="gender_id" ref="gender_id" class="form-label fw-500">Gender<span class="text-danger"> *</span></label>
            <select class="form-control text-xs rounded-5" id="gender_id" v-model="gender_id"
              :class="{ 'is-invalid': errors.gender_id }" required>
              <option selected hidden value="">Select Gender</option>
              <option v-for="gender in genders" :value="gender.id" :key="gender.id">{{ gender.gender }}</option>
            </select>
            <p v-if="errors.gender_id" class="text-danger text-xs">{{ errors.gender_id }}</p>
          </div>
        </div>
        <div class="mb-3">
          <label for="title" ref="title_id" class="form-label fw-500">Title<span class="text-danger"> *</span></label>
          <select class="form-control text-xs rounded-5" id="title" v-model="title_id"
            :class="{ 'is-invalid': errors.title_id }" required>
            <option selected hidden value="">Select Title</option>
            <option v-for="title in titles" :value="title.id" :key="title.id">{{ title.title }}</option>
          </select>
          <p v-if="errors.title_id" class="text-danger text-xs">{{ errors.title_id }}</p>
        </div>
        <div class="mb-3">
          <label for="street_address" ref="street_address" class="form-label fw-500">Street Address<span class="text-danger"> *</span></label>
          <input type="text" class="form-control text-xs rounded-5" id="street_address" v-model="street_address"
            placeholder="Street address" :class="{ 'is-invalid': errors.street_address }" required>
          <p v-if="errors.street_address" class="text-danger text-xs">{{ errors.street_address }}</p>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <label for="town" ref="town" class="form-label fw-500">Town<span class="text-danger"> *</span></label>
            <input type="text" class="form-control text-xs rounded-5" id="town" v-model="town" placeholder="Town"
              :class="{ 'is-invalid': errors.town }" required>
            <p v-if="errors.town" class="text-danger text-xs">{{ errors.town }}</p>
          </div>
          <div class="col-lg-6">
            <label for="postal_code" ref="postal_code" class="form-label fw-500">Postal Code<span class="text-danger"> *</span></label>
            <input type="text" class="form-control text-xs rounded-5" id="postal_code" v-model="postal_code"
              placeholder="Postal code" :class="{ 'is-invalid': errors.postal_code }" required>
            <p v-if="errors.postal_code" class="text-danger text-xs">{{ errors.postal_code }}</p>
          </div>
        </div>
        <div class="mb-3 d-none">
          <button type="button" @click="getLocation" class="btn btn-primary text-white text-xs shadow-sm rounded-5">Get Digital Location</button>
          <p v-if="locationError" class="text-danger text-xs">{{ locationError }}</p>
        </div>
        <div class="mb-3 d-none">
          <div class="row">
            <div class="col-lg-6">
              <label for="latitude" ref="latitude" class="form-label fw-500">Latitude<span class="text-danger"> *</span></label>
              <input type="text" class="form-control text-xs rounded-5" id="latitude" v-model="latitude" placeholder="Latitude"
                :class="{ 'is-invalid': errors.latitude }" required readonly>
              <p v-if="errors.latitude" class="text-danger text-xs">{{ errors.latitude }}</p>
            </div>
            <div class="col-lg-6"> 
              <label for="longitude" ref="longitude" class="form-label fw-500">Longitude<span class="text-danger"> *</span></label>
              <input type="text" class="form-control text-xs rounded-5" id="longitude" v-model="longitude" placeholder="Longitude"
                :class="{ 'is-invalid': errors.longitude }" required readonly>
              <p v-if="errors.longitude" class="text-danger text-xs">{{ errors.longitude }}</p>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <label for="province_id" ref='province_id' class="form-label fw-500">Province<span class="text-danger"> *</span></label>
            <select class="form-control text-xs rounded-5" id="province_id" v-model="province_id"
              :class="{ 'is-invalid': errors.province_id }" required>
              <option selected hidden value="">Select Province</option>
              <option v-for="province in provinces" :value="province.id" :key="province.id">
                {{ province.province_name }}
              </option>
            </select>
            <p v-if="errors.province_id" class="text-danger text-xs">{{ errors.province_id }}</p>
          </div>
        </div>
        <div class="text-end">
          <button type="submit" class="btn btn-primary text-white text-xs shadow-sm rounded-5">Save</button>
        </div>
      </form>
    </div>

    <!-- Company profile -->
    <div class="card mt-3 p-4 rounded-top-5 shadow scrollable-form" v-if="user_details.user_role === 'client'">
      <form @submit.prevent="saveCompanyProfile" id="companyProfileForm" novalidate class="text-xs">
        <div class="mb-3">
          <label for="company_name" ref='company_name' class="form-label fw-500">Company<span class="text-danger"> *</span></label>
          <input type="text" class="form-control text-xs rounded-5" id="company_name" v-model="company_name"
            placeholder="Company Name" required>
        </div>
        <div class="mb-3">
          <label for="registration_number" ref='registration_number' class="form-label fw-500">Registration Number <span class="text-danger"> *</span></label>
          <input type="text" class="form-control text-xs rounded-5" id="registration_number"
            v-model="registration_number" placeholder="Registration Number" required>
        </div>
        <div class="mb-3">
          <label for="organization_id" ref="organization" class="form-label fw-500">Type of Organization<span class="text-danger"> *</span></label>
          <select class="form-select dropdown-toggle text-xs rounded-5" id="organization_id" v-model="organization_id"
            required>
            <option value =""selected hidden>Select Type of Organization</option>
            <option v-for="organization in type_of_organization" :value="organization.id" :key="organization.id">
              {{organization.type_of_organization}}
            </option>
          </select>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <label for="logo" class="form-label fw-500">Logo<span class="text-danger"> *</span></label>
            <input type="file" ref="logo" class="form-control text-xs rounded-5" id="logo" placeholder="Logo" required>
          </div>
          <div class="col-lg-6">
            <label for="symbol" class="form-label fw-500">Symbol<span class="text-danger"> *</span></label>
            <input type="file" ref="symbol" class="form-control text-xs rounded-5" id="symbol" placeholder="Symbol"
              required>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <label for="primary_color" ref="primary_color" class="form-label fw-500">Primary Color<span class="text-danger"> *</span></label>
            <input type="color" class="form-control text-xs rounded-5" id="primary_color" v-model="primary_color"
              required>
          </div>
          <div class="col-lg-6">
            <label for="background_color" ref="background_color" class="form-label fw-500">Secondary Color<span class="text-danger"> *</span></label>
            <input type="color" class="form-control text-xs rounded-5" id="background_color" v-model="background_color"
              required>
          </div>
        </div>
        <div class="mb-3">
          <label for="slogan" ref="slogan" class="form-label fw-500">Slogan<span class="text-danger"> *</span></label>
          <input type="text" class="form-control text-xs rounded-5" v-model="slogan" placeholder="Slogan" required>
        </div>
        <div class="mb-3">
          <label for="street_address" ref="street_address" class="form-label fw-500">Street Address<span class="text-danger"> *</span></label>
          <input type="text" class="form-control text-xs rounded-5" placeholder="Street Address"
            v-model="street_address" required>
        </div>
        <div class="mb-3">
          <label for="phone_number" ref="phone_number" class="form-label fw-500">Tel Number<span class="text-danger"> *</span></label>
          <input type="text" class="form-control text-xs rounded-5" id="phone_number" v-model="phone_number"
            placeholder="Phone number" :class="{ 'is-invalid': errors.phone_number }" required>
          <p v-if="errors.phone_number" class="text-danger text-xs">{{ errors.phone_number }}</p>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <label for="town" ref="town" class="form-label fw-500">Town<span class="text-danger"> *</span></label>
            <input type="text" class="form-control text-xs rounded-5" id="town" v-model="town" placeholder="Town"
              required>
          </div>
          <div class="col-lg-6">
            <label for="postal_code" ref="postal_code" class="form-label fw-500">Postal Code<span class="text-danger"> *</span></label>
            <input type="text" class="form-control text-xs rounded-5" id="postal_code" v-model="postal_code"
              placeholder="Postal Code" required>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <label for="province_id" class="form-label fw-500">Province<span class="text-danger"> *</span></label>
            <select class="form-control text-xs rounded-5" id="province_id" v-model="province_id"
              :class="{ 'is-invalid': errors.province_id }" required>
              <option selected hidden value="">Select Province</option>
              <option v-for="province in provinces" :value="province.id" :key="province.id">
                {{ province.province_name }}
              </option>
            </select>
            <p v-if="errors.province_id" class="text-danger text-xs">{{ errors.province_id }}</p>
          </div>
        </div>
        <div class="text-end">
          <button type="submit" class="btn btn-primary text-white text-xs shadow-sm rounded-5">Save</button>
        </div>
      </form>
    </div>
  </section>
</div>