import Swal from "sweetalert2";

export default {
    data() {
        return ({
        login_url :'',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        errors: {},
        passed: false,
        });
    },
    methods: {

    register_user() {
        this.validateForm();
            
        if (!this.passed) {
            return;
        }
        Swal.fire({
            title: 'Saving...',
            text: 'Please wait while we save the user data.',
            allowOutsideClick: false,
            showConfirmButton: false,
            onOpen: () => {
              Swal.showLoading();
            }
          });
        const protocol = window.location.protocol;
        const host = window.location.host;
        this.login_url = `${protocol}//${host}/sign_in_Finale`;
        $.ajax({
            type: "POST",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/register_user/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "role":"customer",
                "email":this.email,
                "last_name":this.last_name,
                "first_name":this.first_name,
                "phone_number":this.phone_number,
                "login_url":this.login_url
            }),
            success: (response) => {    
                var status = response.status
                if(status == "success"){

                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Registration Successful',
                        text: 'Please check your email for your password.',
                    }).then(() => {
                        window.location = "/sign_in_Finale";
                    });

                }
            },
            error: (response) => {
                Swal.close()
                var response_status = response.responseJSON
                if(response_status.status == 'error'){
                    var response_message=response_status.message.toLowerCase();

                    if (response_message.includes('email')) {
                        this.errors.email = response_message;

                    } else if (response_message.includes('phone number')) {
                        this.errors.phone_number =  response_message;
                    }
                }else{
                    this.validateForm()

                }
             
            }
        });
    },
    validateForm() {
        let valid = true;
        
        if (!this.first_name && this.first_name.length <= 3) {
            this.errors.first_name = 'First name is required';
            valid = false;
        } else {
            this.errors.first_name = '';
        }
        
        if (!this.last_name && this.first_name.length <= 3) {
            this.errors.last_name = 'Last name is required';
            valid = false;
        } else {
            this.errors.last_name = '';
        }
        
        if (!this.email) {
            this.errors.email = 'Email is required';
            valid = false;
        } else if (!this.isValidEmail(this.email)) {
            this.errors.email = 'Please enter a valid email';
            valid = false;
        } else {
            this.errors.email = '';
        }
        
        if (!this.phone_number) {
            this.errors.phone_number = 'Phone number is required';
            valid = false;
        } else if (/\s/.test(this.phone_number)) {
            this.errors.phone_number = 'Phone number should not contain spaces';
            valid = false;
        } else {
            this.errors.phone_number = '';
        }

        this.passed = valid;
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
    isValidPhoneNumber(phone_number) {
        const re = /^[\d\-+()]+$/;
        return re.test(phone_number);
    }
    
 }
};



