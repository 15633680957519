<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
    <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
      <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
        <span class="fw-500">Segments</span>
        <button class="btn btn-warning text-white fw-bold float-end text-xs rounded-5 shadow fw-500 create-topic-btn" data-bs-toggle="modal" data-bs-target="#create_topic">
          <i class="bx bx-plus"></i> Create Segment
        </button>
      </div>
      <div class="card-body">
        <div class="modal fade" id="create_topic" tabindex="-1" aria-labelledby="create_topicModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <p class="modal-title text-xs fw-500" id="create_topicModalLabel">Create a Segment</p>
                <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form @submit.prevent="handleSubmit">
                <div class="modal-body">
        
                  <div class="mb-3">
                    <label for="topic_name" class="form-label text-xs fw-500">Segment Name <span
                        class="text-danger">*</span></label>
                    <input type="text" class="form-control rounded-5 text-xs" id="topic_name" placeholder="Enter topic name"
                      v-model="topic_name">
                      <p v-if="errors.topic_name" class="text-danger text-xs">{{ errors.topic_name }}</p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-striped text-xs">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Workstreams Segment</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(topic, index) in topics" :key="topic.id">
                <th scope="row">{{ index + 1 }}</th>
                <td id="topic_id">{{ topic.name }}</td>
                <td>
                  <router-link :to="'/view_topic/' + topic.id">
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow">
                      <i class="bi bi-eye"></i> View
                    </button>
                  </router-link>
                  <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" data-bs-toggle="modal" :data-bs-target="'#edit_topic_' + topic.id" @click="editTopicNaming(topic)"><i class="bi bi-pencil-square"></i> Edit</button>
                  <button @click="deleteTopic(topic.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm "><i class="bi bi-trash"></i> Delete</button>

                  <div class="modal fade" :id="'edit_topic_' + topic.id" tabindex="-1"
                  :aria-labelledby="edit_topic_ + topic.id + 'Label'" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Edit Segment</p>
                        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <form @submit.prevent="editTopic(topic)">
                        <div class="modal-body">

                          <div class="mb-3">
                            <label :for="'edit_topic_name_' + topic.id" class="form-label text-xs fw-500">Segment Name <span
                                class="text-danger">*</span></label>
                            <input type="text" class="form-control rounded-5 text-xs"
                            :id="'edit_topic_name_' + topic.id" v-model="edit_topic_name">
                            <p v-if="errors.topic_name" class="text-danger text-xs">{{ errors.topic }}</p>
                          </div>

                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</div>