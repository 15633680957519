import { createRouter, createWebHistory } from 'vue-router'
import CryptoJS from 'crypto-js'
import Sign_in_finale from '@/views/Sign_In_Finale/Sign_in_finale.vue'
import Sign_up_Finale from '@/views/Sign_up_Finale/Sign_up_Finale.vue'
import Dashboard from '@/views/Dashboard/DashboardView.vue'
import CreatePortal from '@/views/CreatePortal/CreatePortalView.vue'
import CreateExternalReport from '@/views/CreateExternalReport/ExternalReportView.vue'
import CreateUser from '@/views/CreateUser/CreateUserView.vue'
import ViewPortal from '@/views/ViewPortal/ViewPortalView.vue'
import Home from '@/views/home/HomeView.vue'
import OneTimePin from '@/views/otp/OTPView.vue'
import ResetPassword from '@/views/reset_password/ResetView.vue'
import PasswordChange from '@/views/first_time_password/PasswordView.vue'
import ClientSettings from '@/views/client_settings/ClientSettingsView.vue'
import Profile from '@/views/Profile/Profile.vue'
import ViewTopic from '@/views/ViewTopic/ViewTopicView.vue'
import Home_Filter from '@/views/home_filter/HomeFilterView.vue'
import HomeNavBar from '@/components/HomeNavBar/HomeNavBar.vue'
import CustomerProfile from '@/views/CustomerProfile/CustomerProfile.vue'
import CreateGroup from '@/views/CreateGroup/CreateGroupView.vue'
import CreateAsset from '@/views/CreateAsset/CommunityAsset.vue'
import GroupView from '@/views/group_view/GroupView.vue'

const encryption_key = process.env.VUE_APP_ENCRYPTION_KEY;

export function set_token(token) {
  const encrypted_token = CryptoJS.AES.encrypt(token, encryption_key).toString();
  localStorage.setItem('encrypted_token', encrypted_token);
}

export function get_token() {

  var encrypted_token = localStorage.getItem('encrypted_token');

  if (encrypted_token) {
    var decrypted_bytes = CryptoJS.AES.decrypt(encrypted_token, encryption_key);
    return decrypted_bytes.toString(CryptoJS.enc.Utf8);
  }

  return null;
}

const routes = [

  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/sign_up_Finale',
    name: 'sign_up_Finale',
    component: Sign_up_Finale
  },
  {
    path: '/homenav',
    name: 'homenav',
    component: HomeNavBar
  },
  {
    path: '/sign_in_finale',
    name: 'sign_in_finale',
    component: Sign_in_finale
  },
  {
    path: '/first_password_change',
    name: 'first_password_change',
    component: PasswordChange,
    meta: { requiresAuth: true }
  },

  {
    path: '/client_settings',
    name: 'client_settings',
    component: ClientSettings,
    meta: { requiresAuth: true }
  },

  {
    path: '/otp',
    name: 'otp',
    component: OneTimePin,
    meta: { requiresAuth: true }
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: ResetPassword,
    meta: { requiresAuth: true }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_portal',
    name: 'create_portal',
    component: CreatePortal,
    meta: { requiresAuth: true }
  },

  {
    path: '/view_portal/:portal_id',
    name: 'view_portal',
    component: ViewPortal,
    meta: { requiresAuth: true }
  },

  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_external_report',
    name: 'create_external_report',
    component: CreateExternalReport,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_user',
    name: 'create_user',
    component: CreateUser,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_group',
    name: 'create_group',
    component: CreateGroup,
    meta: { requiresAuth: true }
  },
  {
    path: '/create_asset',
    name: 'create_asset',
    component: CreateAsset,
    meta: { requiresAuth: true }
  },
  {
    path: '/view_group/:group_id/:group_name/:created_by',
    name: 'view_group',
    component: GroupView,
  },

  {
    path: '/view_topic/:topic_id',
    name: 'view_topic',
    component: ViewTopic,
    props: true
  },

  {
    path: '/home_filter/:portal_id/:portal_name',
    name: 'home_filter',
    component: Home_Filter,
  },

  {
    path: '/customer_profile',
    name: 'customer_profile',
    component: CustomerProfile
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('encrypted_token');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export function clear_token() {
  localStorage.removeItem('encrypted_token');
}

export function set_user_details(user_details) {

  var user_role = user_details.role.role;
  var first_name = user_details.first_name;
  var last_name = user_details.last_name;
  var user_id = user_details.id;
  var first_time_login = user_details.first_time_login;
  var phone_number = user_details.phone_number;
  var email = user_details.email;

  localStorage.setItem('user_role', user_role);
  localStorage.setItem('first_name', first_name);
  localStorage.setItem('last_name', last_name);
  localStorage.setItem('user_id', user_id);
  localStorage.setItem('first_time_login', first_time_login);
  localStorage.setItem('phone_number', phone_number);
  localStorage.setItem('email', email);

}

export function get_user_details(user_details) {

  const user_role = localStorage.getItem('user_role');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  const user_id = localStorage.getItem('user_id');
  const phone_number = localStorage.getItem('phone_number');
  const email = localStorage.getItem('email');


  var user_details = {
    "user_role":user_role,
    "first_name":first_name,
    "last_name":last_name,
    "user_id":user_id,
    "phone_number":phone_number,
    "email":email
  
  }

  return user_details;

}


export default router