import { get_token } from "@/router";

import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';

export default {

  components: {
    Sidebar,
    Navbar
  },

  mounted() {
    
    $('#loader').show();
    this.get_dashboard();
  },

  data() {
    var data = {
      total_users: 0,
      total_portals: 0,
      total_reports: 0,
    }

    return data;
  },

  methods: {

    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },

    get_dashboard() {

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/dashboard/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${get_token()}`,
          'Content-Type': 'application/json'
        },
        success: (reponse) => {

          var response_data = reponse.data;

          this.total_users = response_data.total_users || 0;
          this.total_portals = response_data.total_portals || 0;
          this.total_reports = response_data.total_reports || 0;

        },
        complete: () => {
          this.isLoading = false;
        }
      });

    },

  }

}