import {get_user_details } from "@/router";

export default {

    data() {
      return ({
        user_details: {
          "user_role": '',
        },
      })
    },
    mounted() {
      this.user_details = get_user_details();
    },

    methods: {
      toggleSidebar() {
        this.$emit('toggle-sidebar');
      }
    }
  }