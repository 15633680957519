


<section class="image-background">
  <div class="form-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 col-sm-6">
                <div class="form-container rounded-5 shadow p-5 top-20">
                  <img :src="$clientLogo"  class="img-fluid mx-auto d-block w-150" alt="">
                    <h3 class="title text-center">Sign-up</h3>
                     <form >
                      <div class="row mb-2">
                        <div class="col-md-6">
                          <label for="first_name" class="form-label text-xs fw-500">First Name<span class="text-danger">*</span></label>
                          <input type="text" class="form-control rounded-5 text-xs p-2" placeholder="Enter your first name" id="first_name" v-model="first_name" required>
                          <span v-if="errors.first_name" class="text-danger text-xs">{{ errors.first_name }}</span>
                        </div>
                        <div class="col-md-6">
                          <label for="last_name" class="form-label text-xs fw-500">Last Name<span class="text-danger">*</span></label>
                          <input type="text" class="form-control rounded-5 text-xs p-2 mb-1" id="last_name" placeholder="Enter last name" v-model="last_name" required>
                          <span v-if="errors.last_name" class="text-danger text-xs">{{ errors.last_name }}</span>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12">
                          <label for="email" class="form-label text-xs fw-500">Email<span class="text-danger">*</span></label>
                          <input type="email" class="form-control rounded-5 text-xs p-2" placeholder="example@gmail.com" id="email" v-model="email" required>
                          <span v-if="errors.email" class="text-danger text-xs">{{ errors.email }}</span>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12">
                          <label for="phone_number" class="form-label text-xs fw-500">Phone number<span class="text-danger">*</span></label>
                          <input type="text" class="form-control rounded-5 text-xs p-2 mb-1" id="phone_number" placeholder="Enter phone number" v-model="phone_number" required>
                          <span v-if="errors.phone_number" class="text-danger text-xs">{{ errors.phone_number }}</span>
                        </div>
                      </div>
                      <button type="button" class="btn btn-primary rounded-5 shadow-sm w-100 mt-3 mb-1" @click="register_user">Sign up</button>
                    </form>
                  </div>
            </div>
        </div>
    </div>
</div>
</section>
