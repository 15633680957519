<nav class="navbar dashboard navbar-expand-lg navbar-light bg-white py-4 px-4">
  <div class="d-flex align-items-center">
    <!-- Conditionally show sidebar toggle or logo -->
    <i v-if="user_details.user_role !== 'customer'" class='bx bx-menu fs-5' @click="toggleSidebar"></i>
    <a v-else class="navbar-brand me-auto" href="#">
      <img :src="$clientLogo" class="img-fluid logo" alt="Logo" />
    </a>
  </div>
  <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
    <li class="nav-item d-sm-block d-lg-block">
      <a class="nav-link fs-6 fw-500" href="/">
        <i class='bx bx-home'></i> Home
      </a>        
    </li>
  </ul>
</nav>
