import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import { get_token } from "@/router";
import Swal from "sweetalert2";

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      external_reports: [],
      report_name: '',
      report_url: '',
      upload_media: '',
      errors: {},
      isSubmitting: false,
    });
  },
    
  mounted() {
    this.fetchExternalReports().then(() => {
      this.$nextTick(() => {
        // Loop through all tables with the 'table' class
        $('.table').each(function() {
          $(this).DataTable({
            paging: true,
            searching: true,
            ordering: true,
            info: true,
            lengthChange: true,
            pageLength: 10,
            language: {
              paginate: {
                previous: "Previous",
                next: "Next"
              },
              info: "Showing _START_ to _END_ of _TOTAL_ entries",
              infoEmpty: "No entries available"
            }
          });
        });
      });
    });
    
  },
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },
    async validateForm() {
      this.errors = {};
      let isValid = true;
      if (this.report_url && this.report_url.trim()) {
        try {
          new URL(this.report_url);
        } catch (_) {
          this.errors.report_url = 'Please enter a valid URL.';
          isValid = false;
        }
      }

      return isValid;
    },


    getToken() {
      return get_token();
    },
    validationExtReportForm() {
      this.errors = {};
      let valid = true;

      if (!this.report_name) {
        this.errors.report_name = 'External feed name is required';
        valid = false;
      }

      if (!this.report_url) {
        this.errors.report_url = 'URL is required';
        valid = false;
      }

      return valid;
    },
    async submit_form() {
      if(!this.validationExtReportForm()) {
        return;
      }

      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('external_report_name', this.report_name);
      if (this.report_url) {
        formData.append('external_link', this.report_url);
      }
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_external_report_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: () => {
          Swal.close();
        
          Swal.fire({
            icon: 'success',
            title: 'Feed created',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.resetForm();
            window.location.reload();
          });
        },
        error: () => {
          Swal.close();
          this.errors.report_name="Feed Name Already Exists"
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    handleFileUpload(event) {
      this.upload_media = event.target.files[0];
    },
    resetForm() {
      this.report_name = '';
      this.report_url = '';
      this.upload_media = '';
      this.errors = {};
    },

    fetchExternalReports() {
      this.isLoading = true;
      return new Promise((resolve) => {
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_external_reports_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.external_reports = response.reports
            resolve();
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    });
    },

    delete_external_report(external_report_id) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) { 
          $.ajax({
            type: 'DELETE',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_external_report_api/`,
            data: {external_report_id: external_report_id},
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: () => {
              Swal.fire(
                {
                  icon: 'success',
                  title: 'External Feed successfully deleted',
                  showConfirmButton: false,
                  timer: 2000
                }
              );
              window.location.reload();
            },
            error: () => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to delete the external feed. Please try again.',
              })
            }
          });
        }
      })
    }
  }
};