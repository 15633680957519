<div class="offcanvas offcanvas-end" tabindex="-1" id="topics" aria-labelledby="topics_label">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="portals_label">Segments</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body"> 
      <div class="reports">
        <hr />
        <div class="table-container">
          <table v-if="topics.length" class="table mb-0">
            <tbody>
              <tr v-for="topic in topics" :key="topic.id" class="hover-row border-0">
                <td class="border-0">
                  
                  <a class="text-decoration-none text-dark" href="#" @click.prevent="handleTopicClick(topic.id)" data-bs-dismiss="offcanvas" >
                   
                    <p class="portal-icon mb-0 d-flex align-items-center">
                      <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                        <i class='bx bxs-bulb fs-5'></i>
                      </span>
                      <span class="text-xs fw-500 mx-2">{{ topic.name || topic_name.name }}</span>
                    </p>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="text-secondary text-xs text-center">
            No Segments Available
          </p>
        </div>
      </div>
    </div>

  </div>
  <div class="col-lg-3 d-none d-xl-block mt-4">
    <!-- Segments Section -->
    <div class="mx-5 rounded-5 p-2 pb-5 d-none d-md-block">
      <div class="reports">
        <h6>Segments</h6>
        <hr />
        <div class="modal fade" id="create_topic" tabindex="-1" aria-labelledby="create_topicModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content rounded-5">
              <form @submit.prevent="handleSubmit">
                <div class="modal-body">
                  <h6 class="modal-title mb-3" id="create_topicModalLabel">Create Segment</h6>
                  <div class="mb-3">
                    <label for="topic_name" class="form-label text-xs fw-500">Segment Name <span
                        class="text-danger">*</span></label>
                    <input type="text" class="form-control rounded-5 text-xs" id="topic_name"
                      placeholder="Enter segment name" v-model="topic_name" required>
                    <p v-if="errors.topic_name" class="text-danger text-xs">{{ errors.topic_name }}</p>
                  </div>
                  <div class="float-end mb-3">
                    <button type="button" class="btn btn-primary text-xs rounded-5 shadow mx-1"
                      data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" class="btn btn-primary text-xs rounded-5 shadow">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="table-container">
          <table v-if="topics.length" class="table mb-0">
            <tbody>
              <tr v-for="topic in topics" :key="topic.id" class="hover-row border-0">
                <td class="border-0">
                  <a class="text-decoration-none text-dark" href="#" @click.prevent="handleTopicClick(topic.id)">
                    <p class="portal-icon mb-0 d-flex align-items-center">
                      <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                        <i class='bx bxs-bulb fs-5'></i>
                      </span>
                      <span class="text-xs fw-500 mx-2">{{ topic.name || topic_name.name }}</span>
                    </p>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="text-secondary text-xs text-center">No Segments Available</p>
        </div>
      </div>
    </div>

    <!-- Portal Members Section -->
    <div class="mx-5 rounded-5 p-2 pb-5 mt-4 d-none d-md-block">
      <div class="reports" v-if="!is_public">
        <h6>Portal Members</h6>
        <hr />
        <div class="table-container">
          <table v-if="members.length" class="table mb-0">
            <tbody>
              <tr v-for="member in members" :key="member.id" class="hover-row border-0">
                <td class="border-0">
                  <a class="text-decoration-none text-dark">
                    <p class="portal-icon mb-0 d-flex align-items-center">
                      <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                        <i class='bx bxs-user fs-5'></i>
                      </span>
                      <span class="text-xs fw-500 mx-2">{{ member.first_name }} {{ member.last_name }}</span>
                    </p>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="text-secondary text-xs text-center">No Members Available</p>
        </div>
      </div>
    </div>
  </div>
  