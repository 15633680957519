import {get_token,get_user_details} from "@/router";
import Swal from 'sweetalert2';

export default {
    data() {
      return ({
        token_exists: false,
        user_details: {
          "user_role": '',
        },
        isDropdownVisible: false,
        fileName:'',
        topics:[],
        created_by_id:this.created_by,
        portals:[],
        categories: [],
        selectedPortalId: null,
        selectedTopicId: null,
        selectedVisibility: 'public',
        errors: {
          report_name: null,
          voice_description:null,
          voice_name:null,
          audio_file :null,
          portal: null,
          segment: null,
        },
        users:[],
        selectedUserIds: [],

        isRecording: false,
        audio_file: null,
        mediaRecorder: null,
        recordedChunks: [],
        audio_file_name: '',
        upload_media: null,
        fileError : '',
        audioError: '',
      });
    },
    props: {
      group_id: {
        type: String,
        required: false,
        default: null
      },
      created_by: {
        type: String,
        required: false,
        default: null
      }
    },

    mounted() {
      this.token_exists = get_token();
      this.user_details=get_user_details();
      this.get_community_assets_category();
      this.fetchPortals();
      this.fetch_users();

      const feedModal = $('#feed_modal');
      const voiceModal = $('#voice_modal');
      const createCommunityAssetModal = $('#create_community_asset_modal');
      const groupVoiceModal = $('#group_voice_modal');
      const groupFeedModal = $('#group_feed_modal');

      feedModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      voiceModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      createCommunityAssetModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      groupVoiceModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      groupFeedModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });
    },
    watch: {
      '$route.params.group_id'() {
        this.fetch_users();
      }
    },

    computed: {
      isPublic() {
        return this.selectedVisibility === 'public';
      },
      isHomePage() {
        return this.$route.name === 'home'; // Assuming your home page route is named 'home'
      },
      isPortalPage() {
        return this.$route.name === 'home_filter'; 
      },
      isGroupPage() {
        return this.$route.name === 'view_group';
      }
    },
    
    methods: {
      resetPortalSelection() {
        this.selectedPortalId = ''; 
        this.selectedTopicId = '';
        this.errors.portal = '';
        this.errors.segment = '';
        this.errors.name = '';
        this.fileName = '';
        this.errors.report_name = '';
        this. audio_file_name = '';
        this.fileError = '';
      },
      toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
      },
      handleCustomer(){
        const role =this.user_details.user_role
        if(role === 'customer'){
          return false
      }
      else {
        return true
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.type.startsWith('audio/')) {
          this.fileError = 'Audio files are not allowed.';
          this.upload_media = null;
          this.fileName = '';
          event.target.value = '';
        } else {
          this.fileName = file.name;
          this.fileError = '';
          this.upload_media = file;
        }
      } else {
        this.fileName = '';
        this.fileError = '';
        this.upload_media = null;
      }
    },
      onPortalChange() {
        if (this.selectedPortalId) {
          this.fecthTopics(this.selectedPortalId);
        }
      },
      getToken() {
        return get_token();
      },
      startRecording() {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then((stream) => {
            this.mediaRecorder = new MediaRecorder(stream);
      
            this.mediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                this.recordedChunks.push(event.data);
              }
            };
      
            this.mediaRecorder.onstop = () => {
              const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav' });
              this.audio_file = audioBlob;
              this.audio_file_name = 'recording.wav';
            };
      
            this.mediaRecorder.start();
            this.isRecording = true;
          })
          .catch((error) => {
            if (error.name === 'NotAllowedError') {
              Swal.fire({
                icon: 'error',
                title: 'Microphone Access Denied',
                text: 'Please allow microphone access in your browser settings and refresh the page.',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Error accessing microphone: ${error.message}`
              });
            }
          });
      },

      stopRecording() {
        if (this.mediaRecorder && this.isRecording) {
          this.mediaRecorder.stop(); 
          this.isRecording = false;
        }
      },
      handleAudioUpload(event) {
        const file = event.target.files[0];
        if (file) {
          if (!file.type.startsWith('audio/')) {
            this.audioError = 'Only audio files are allowed.';
            this.audio_file = null;
            this.audio_file_name = '';
            event.target.value = '';
          } else {
            this.audio_file_name = file.name;
            this.audioError = '';
            this.audio_file = file;
          }
        } else {
          this.audio_file_name = '';
          this.audioError = '';
          this.audio_file = null;
        }
      },
      fetchPortals() {
        this.isLoading = true;
        $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
        method: 'GET',
        headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
        },
        success: (data) => {
            this.portals = data.portals;
        },
        complete: () => {
            this.isLoading = false;
        }
        });
    },
    fetch_users() {
      return new Promise((resolve) => {

        const groupId = this.$route.params.group_id;
      $.ajax({
        type: 'GET',
        data:{group_id:groupId},
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/retrieve_users/',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          this.users = response.data.users_list;
          resolve();
        },
      });
    });
    },
    fecthTopics(portal_id) {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_topics_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: {portal_id: portal_id},
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.topics = response.topics
            if (this.topics.length === 0) {
              Swal.fire({
                icon: 'warning',
                title: 'No Topics Available',
                text: 'The selected portal has no topics associated with it.',
                confirmButtonText: 'OK'
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to fetch topics. Please try again.',
              confirmButtonText: 'OK'
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    validationFeedForm() {
      this.errors = {};
      let valid = true;

      if (!this.report_name) {
        this.errors.report_name = 'Feed name is required';
        valid = false;
      }

      if (!this.selectedPortalId) {
        this.errors.portal = "You must tag a portal.";
      }
    
      if (!this.selectedTopicId) {
        this.errors.segment = "You must tag a segment.";
      }
      return valid;
    },
    submit_form() {
      if(!this.validationFeedForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('report_name', this.report_name);
      if (this.report_url) {
        formData.append('report_link', this.report_url);
      }
      formData.append('portal_id', this.selectedPortalId);
      formData.append('topic_id', this.selectedTopicId);
      this.report_description = this.$refs.quillReportEditorRef.getHTML()
      formData.append('description', this.report_description);
      formData.append('is_public', this.isPublic);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      if (this.audio_file) {
        formData.append('file', this.audio_file,this.audio_file_name);
      }
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_report_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          Swal.close();
          const status = response.status;

          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Feed Created',
              showConfirmButton:false,
              timer:2000,

            }).then(() => {
              window.location.reload();
            });

          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status==="error") {
            this.errors.report_name=response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      })
    },
    create_group_feed() {
      const formData = new FormData();
      formData.append('feed_name', this.group_report_name);
      this.feed_report_description = this.$refs.quillFeedReportEditorRef.getHTML()
      formData.append('description', this.feed_report_description);
      formData.append('group_id',this.group_id)
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      if (!this.group_report_name) {
        this.errors.report_name = 'Feed name is required';
        return;
      }
      if (this.audio_file) {
        formData.append('file', this.audio_file,this.audio_file_name);
      }
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group_feed/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        beforeSend: function () {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
        },
        success: (response) => {
          Swal.close();
          const status = response.status;

          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Feed Created',
              showConfirmButton:false,
              timer:2000,

            }).then(() => {
              window.location.reload();
            });

          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status==="error") {
            this.errors.report_name=response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      })
    },

    create_group_voice() {
      const formData = new FormData();
      formData.append('voice_name', this.group_voice_name);
      this.group_voice_description = this.$refs.quillVoiceDescriptionRef.getHTML();
      formData.append('description', this.group_voice_description);
      formData.append('group_id', this.group_id);

      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      if (this.audio_file) {
        formData.append('file', this.audio_file, this.audio_file_name);
      }

      if (!this.group_voice_name) {
        this.errors.voice_name = 'voice name is required';
        return;
      }

      if (this.errors.audio_file ||this.errors.upload_media) { 
        return;
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group_voice/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        beforeSend: function() {
          Swal.fire({
            text: 'Creating Group Voice...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          const status = response.status;
    
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Group Voice Created',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.reload();
            });
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.voice_name = response_data.message;
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },

    validationHubForm() {
      this.errors = {};
      let valid = true;

      if (!this.name) {
        this.errors.name = 'Your voice name is required';
        valid = false;
      }

      if (!this.selectedPortalId) {
        this.errors.portal = "You must tag a portal.";
      }
    
      if (!this.selectedTopicId) {
        this.errors.segment = "You must tag a segment.";
      }
      return valid;
    },
    createHub() {
      if(!this.validationHubForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('topic', this.selectedTopicId);
      formData.append('creater', this.selectedTopicId);
      this.description = this.$refs.quillHubRef.getHTML()
      formData.append('description', this.description);
    
      if (this.upload_media) {
        formData.append('document', this.upload_media);
      }
      if (this.audio_file) {
        formData.append('document', this.audio_file,this.audio_file_name);
      }

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_exchange_hub_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: () => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Voice Created',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            window.location.reload();
          });
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status==="error") {
            const message=response_data.message
            if(message.includes('id')){
              this.errors.segment="You must tag a segment."
            }
            else{
              this.errors.name=response_data.message
            }
            
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    validationPortalForm() {
      this.errors = {};
      let valid = true;

      if (!this.portal_name) {
        this.errors.portal_name = 'Portal name is required';
        valid = false;
      }

      return valid;
    },
    createPortal() {
      if(!this.validationPortalForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('portal_name', this.portal_name);
      formData.append('is_public', this.isPublic);

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_portal_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: () => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Portal Created',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            window.location.reload();
          });
        },
        error: (response) => {
          const response_error=response.responseJSON
          Swal.close();
          if(response_error.status==="error"){
            this.errors.portal_name=response_error.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    validationGroupForm() {
      this.errors = {};
      let valid = true;

      if (!this.group_name) {
        this.errors.group_name = 'Group name is required';
        valid = false;
      }

      return valid;
    },
    createGroup() {
      if(!this.validationGroupForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('name', this.group_name);
      this.description = this.$refs.quillGroupEditorRef.getHTML()
      formData.append('description', this.description);
      formData.append('is_public', this.isPublic);

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: () => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Group Created',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            window.location.reload();
          });
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          
          if (response_data.status==="error") {

            this.errors.group_name=response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    validationCommunityForm() {
      this.errors = {};
      let valid = true;

      if (!this.asset_name) {
        this.errors.asset_name = 'Asset name is required';
        valid = false;
      }

      if (!this.location) {
        this.errors.location = 'Location is required';
        valid = false;
      }

      if (!this.category_id) {
        this.errors.category_id = 'Category selection is required';
        valid = false;
      }
      return valid;
    },
    createcommunity_asset() {
      if(!this.validationCommunityForm()) {
        return;
      }
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('name', this.asset_name);
      this.description = this.$refs.quillAssetEditorRef.getHTML()
      formData.append('description', this.description);
      formData.append('location', this.location);
      formData.append('category_id', this.category_id);
      formData.append('subscription_fee', this.subscription_fee);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_community_asset/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
       
        success: (response) => {
          const status = response.status;
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Community asset Created Successfully',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload();
              $('#create_community_asset_modal').modal('hide');
            });
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.asset_name  = response_data.message
            
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    get_community_assets_category() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_community_assets_category/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          this.categories = response.data.categories;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    validationSegmentForm() {
      this.errors = {};
      let valid = true;

      if (!this.topic_name) {
        this.errors.topic_name = 'Segment name is required';
        valid = false;
      }

      if (!this.selectedPortalId) {
        this.errors.portal = "You must tag a portal.";
      }

      return valid;
    },
    add_group_member() {
      if (this.selectedUserIds.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Please select at least one user.',
          showConfirmButton: true
        });
        return;
      }
      const data ={
        group_id :this.group_id,
        user_id :this.selectedUserIds
      }
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_group_member/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data),
        success: (response) => {
          const status = response.status;
          if (status === "success") {
  
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Group member(s) added',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload();
            });
          }
        },
        error: () => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text:"User already Exist" ,
            showConfirmButton: false,
            timer: 2000
          })
         
        }
      });
  },
    createSegment() {
      if(!this.validationSegmentForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const requestData = {
          topic_name: this.topic_name,
          portal_id: this.$route.params.portal_id
        };

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_topic_api/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(requestData),
        success: (response) => {
          
          const status = response.status;
          
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Segment Created Successfully',
              text: 'Your segment has been created.',
              showConfirmButton: false,
              timer: 1000
            }).then(() => {
              window.location.reload();
            });
          } else if (status === "error") {
            Swal.fire({
              icon: 'error',
              title: 'Creation Failed',
              text: response.message || 'An error occurred. Please try again.',
              confirmButtonText: 'Retry'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Unexpected Response',
              text: 'The server returned an unexpected response.',
              confirmButtonText: 'Okay'
            });
          }
        },
        error: (response) => {
          const response_data = response.responseJSON;
          if(response_data.status=="error"){
           this.errors.topic_name=response_data.message
          }
      },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    }
   },
};