<section class="image-background">
  <div class="form-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-sm-6">
                <div class="form-container rounded-5 shadow p-5">
                    <h3 class="title text-center">Company Information</h3>

                    <p class="d-block mb-3 text-center text-secondary">Building the youth of tomorrow today</p>
                    <form>

                      <div class="row">
                          <div class="col-12">
                            <label for="company" class="form-label text-xs fw-500">Company name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control rounded-5 text-xs p-2 mb-1" id="company" placeholder="Enter company name" v-model="company" required>
                          </div>
                          <div class="col-12">
                            <label for="slogan" class="form-label text-xs fw-500">Slogan<span class="text-danger">*</span></label>
                            <input type="text" class="form-control rounded-5 text-xs p-2 mb-1" id="slogan" placeholder="Enter slogan" v-model="slogan" required>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                              <label for="back_color" class="form-label form-label text-xs fw-500">Choose backgroud color</label>
                              <input type="color" class="form-control form-control-color" id="back_color" value="#563d7c" title="Choose your color">
                          </div>
                          <div class="col-md-6">
                              <label for="primary_color" class="form-label form-label text-xs fw-500">Choose primary color</label>
                              <input type="color" class="form-control form-control-color" id="primary_color" value="#563g0t" title="Choose your color">
                          </div>

                      </div>
                        <div>
                          <div class="col-12">


                            <label for="slogan" class="form-label text-xs fw-500">Logo<span class="text-danger">*</span></label>
                            <input type="file" class="form-control rounded-5 text-xs">
                          </div>
                          <div class="col-12">
                            <label for="slogan" class="form-label text-xs fw-500">Symbol<span class="text-danger">*</span></label>
                            <input type="file" class="form-control rounded-5 text-xs">
                          </div>
                        </div>
                       <router-link to="dashboard"><button type="button" class="btn btn-primary rounded-5 shadow-sm w-100 mt-3 mb-1">Continue</button></router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</section>