import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import AllGroups from "@/components/GetGroups/GroupsComponent.vue"
import GroupFeedCommentModal from "@/components/GroupFeedCommentModal/GroupFeedComment.vue"
import GroupVoiceCommentModal from "@/components/GroupVoiceCommentModal/GroupVoiceComment.vue";
import FloatingButton from "@/components/FloatingButton/FloatingButtonComponent.vue";
import StickyNav from "@/components/BottomNavbar/StickyNavbar.vue"
import Swal from 'sweetalert2';
import { get_token,get_user_details } from "@/router";


export default {
  components: {
    HomeNavBar,
    AllGroups,
    StickyNav,
    GroupFeedCommentModal,
    GroupVoiceCommentModal,
    FloatingButton,
    group_creator_id: null,
    isCreator: false,
  },
  mounted() {
    this.token_exists = get_token();
    this.user_id = this.get_user_id();
    this.fetchGroupMembers()
    this.fetchGroupVoices();
    this.fetchGroupFeeds();
  },
  watch: {
    '$route.params.group_id'() {
      this.fetchGroupMembers();
    }
  },
  data() {
    return ({
      group_members: {},
      token_exists: false,
      user_id: null,
      selectedMembers: [],
      user_details:get_user_details(),
      group_creator_id: null,
      isGroupCreator: false,
      isCreator: false,
      promptExitGroup: [],
      isExitIconVisible: false,
      comment_modal_visible: false,
      voice_comment_modal_visible: false,
      deleteMode: false,
      exitMode: false,
      group_voices:[],
      group_feeds:[],
      feed_data: {},
      voice_data: {},
      expandedFeeds: [],
    });
  },
  methods: {
    handle_unauthenticated() {
      if (!this.token_exists) {
        this.$router.push({ name: 'sign_in_finale', },
        );
        return false;
      }
      return true;
    },
    getToken() {
      return get_token();
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
    },
    fetchGroupMembers() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_group_member/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },

        data: { group_id: this.$route.params.group_id },
        success: (response) => {
          this.group_members = response.members;
          this.group_creator_id = response.created_by;
          this.user_id = this.get_user_id();
          this.isCreator = Number(this.user_id) === Number(this.group_creator_id);
          },
        error: () => {
          this.group_members = ''
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },
    showDeleteMode() {
      this.deleteMode = true;
      this.exitMode = false;
    },

    display_comment_modal(feed_data) {

      if (!this.handle_unauthenticated()) {
        return;
      }

      this.feed_data = feed_data;
      this.comment_modal_visible = true;
    },

    hide_comment_comment() {
      this.feed_data = {};
      this.comment_modal_visible = false;
    },
    handleExitCheckboxChange(event) {
      this.isExitIconVisible = event.target.checked;
    },
    showExitMode() {
      this.deleteMode = true;
      this.exitMode = false;
      Swal.fire({
        title: 'Exit Group',
        text: "You are about to exit a group.Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, exit'
      }).then((result) => {
        if (result.isConfirmed) {
          this.memberExitGroup();
        }
      });
    },

    fetchGroupVoices() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_group_voice/`, 
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { group_id: this.$route.params.group_id},
        success: (response) => {
          var status = response.status;
          if (status === "success") {
            this.group_voices= response.voice_data;

          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },

    fetchGroupFeeds() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_group_feed/`, 
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { group_id: this.$route.params.group_id},
        success: (response) => {
          var status = response.status;
          if (status === "success") {
            this.group_feeds= response.feeds;

          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },

    deleteGroupVoice(voice_id) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Deleting...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_group_voice/`,
            data: JSON.stringify({ voice_id: voice_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The group voice has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload();
                });
              }
            }
          });
        }
      });
    },


    memberExitGroup() {
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/member_exit_group/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${this.getToken()}`,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          group_id: this.$route.params.group_id,
        }),
        success: (response) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.message,
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            window.location.reload();
            this.$router.push(`/view_group/${this.$route.params.group_id}/${this.$route.params.group_name}`);
          });
        },
        error: (xhr) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: xhr.responseJSON?.message || 'Failed to exit group',
          });
        },
      });
    },
    removeSelectedMembers() {
      if (this.selectedMembers.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'No members selected for removal.',
        });
        return;
      }

      Swal.fire({
        title: 'Remove Members',
        text: "You are about to remove a user from the group.Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/remove_group_member/`,
            method: 'DELETE',
            headers: {
              Authorization: `Token ${this.getToken()}`,
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              user_id: this.selectedMembers,
              group_id: this.$route.params.group_id,
            }),
            success: (response) => {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.message,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.reload();
                this.$router.push(`/view_group/${this.$route.params.group_id}/${this.$route.params.group_name}`);
              });
            },
            error: (xhr) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: xhr.responseJSON?.message || 'Failed to remove members.',
              });
            },
          });
        }
      });
    },

    deleteGroupFeed(feed_id) {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Deleting...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_group_feed/`,
            data: JSON.stringify({ feed_id: feed_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The group feed has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload();
                });
              }
            }
          });
        }
      });
    },

    truncateDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedFeedDescription(feed) {
      const description = feed.description;

      if (this.isFeedExpanded(feed.id)) {
        return description;
      }
  
      return this.truncateDescription(description);
    },
    isFeedExpanded(feedId) {
      return this.expandedFeeds.includes(feedId);
    },

    shouldShowReadMore(feed) {
      const description = feed.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },
    toggleFeedReadMore(feedId) {
      const index = this.expandedFeeds.indexOf(feedId);
      if (index === -1) {
        this.expandedFeeds.push(feedId);
      } else {
        this.expandedFeeds.splice(index, 1);
      }
    },

    display_voice_comment(voice_data) {
      if(!this.handle_unauthenticated()){
        return;
      }
      this.voice_data = voice_data;
      this.voice_comment_modal_visible = true;
    },

    hide_voice_comment_comment() {
      this.voice_data = {};
      this.voice_comment_modal_visible = false;
    },
  }
}
