import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import CommunityAssetModal from '@/components/CommunityAssetModal/CommunityAssetModal.vue';
import { get_token } from "@/router";
import Swal from 'sweetalert2';

export default {
  components: {
    Sidebar,
    Navbar,
    CommunityAssetModal,
  },
  watch: {
    community_asset_data(newData) {
        if (newData) {
            this.set_communit_asset(newData);
            this.show_modal();
        }
    }
},

  mounted() {
    this.token_exists = get_token();
    this.get_community_assets_category();
    this.fetchcommunity_assets().then(() => {
    this.$nextTick(() => {
      $('.table').DataTable({
        paging: true,
        searching: true,
        ordering: true,
        info: true,
        lengthChange: true,
        pageLength: 10,
        language: {
          paginate: {
            previous: "Previous",
            next: "Next"
          },
          info: "Showing _START_ to _END_ of _TOTAL_ entries",
          infoEmpty: "No entries available"
        },
        sortColumn: '',
        sortDirection: 'asc'
      });
    });
  })
  },

  data() {
    return ({
      token_exists: false,
      isDropdownVisible: false,
      fileName:'',
      asset_id:'',
      edit_asset_name:'',
      edit_asset_location:'',
      categories: [],
      community_assets: [],
      selectedPortalId: null,
      selectedVisibility: 'public',
      errors: {},
      isModalVisible: false,
      selectedAssetData: null,

      asset_data: {
        community_asset_files: [],
        name: '',
        description: '',
        location: '',
    },
    });
  },
  methods: {
    openModal(asset) {
      this.selectedAssetData = asset; 
      this.isModalVisible = true; 
    },
    setAssetData(asset) {
      this.set_communit_asset(asset);
      this.show_modal();
  },
  show_modal() {
    $("#community_asset_modal").modal("show");
},

    getToken() {
      return get_token();
    },
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },
    fetchcommunity_assets() {
      return new Promise((resolve) => {
        this.isLoading = true;
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_community_asset/`,
          method: 'GET',
          headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
          },
          success: (data) => {
            this.community_assets = data.community_assets;
            resolve();
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      });
    },
  
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;
      } else {
        this.fileName = ''; 
      }
      this.upload_media = file;
    },
    
    handle_unauthenticated() {
      if (!this.token_exists) {
        this.$router.push({ name: 'sign_in_finale' });
        return false;
      }
      return true;
    },
    validationCommunityForm() {
      this.errors = {};
      let valid = true;

      if (!this.asset_name) {
        this.errors.asset_name = 'Asset name is required';
        valid = false;
      }

      if (!this.location) {
        this.errors.location = 'Location is required';
        valid = false;
      }

      if (!this.category_id) {
        this.errors.category_id = 'Category selection is required';
        valid = false;
      }
      return valid;
    },
    createcommunity_asset() {
      if(!this.validationCommunityForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we create community asset.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('name', this.asset_name);
      this.description = this.$refs.quillAssetEditorRef.getHTML()
      formData.append('description', this.description);
      formData.append('location', this.location);
      formData.append('category_id', this.category_id);
      formData.append('subscription_fee', this.subscription_fee);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
   
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_community_asset/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: (response) => {
          const status = response.status;
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Community asset Created Successfully',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.community_assets.unshift(response.community_asset_data);
              $('#create_community_asset').modal('hide');
            });
            
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.asset_name  = response_data.message
            
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    editAssetName(asset) {
      this.asset_id=asset.id
      this.edit_asset_name=asset.name
      this.edit_asset_location=asset.location
      },
      updateCommunityAsset() {
      const data = {
        community_asset_id: this.asset_id,
        community_asset_name: this.edit_asset_name,
        location:this.edit_asset_location
      }

      $.ajax({
        type: 'PUT',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/update_community_asset/`,

        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: (response) => {
          const status = response.status;

          if (status === "success") {
        Swal.fire({
          icon: 'success',
          title: 'Asset Updated',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {

          this.community_assets.forEach(asset => {
            if (asset.id === this.asset_id) {
              asset.name = this.edit_asset_name;
              asset.location = this.edit_asset_location;
            }
            $(`#edit_asset_${asset.id}`).modal('hide');
          });
        });
          this.formHidden = true;
          
          }
        }
      });
    },

    deleteAsset(community_asset_id) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_community_asset/`,
            data: {
              community_asset_id: community_asset_id
            },
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The segment has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  this.community_assets = this.community_assets.filter(asset => asset.id !== community_asset_id);
                });
              } else {
                Swal.fire(
                  'Failed!',
                  response.message || 'An error occurred. Please try again.',
                  'error'
                );
              }
            },
          });
        }
      });
    },

    get_community_assets_category() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_community_assets_category/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          this.categories = response.data.categories;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
  }
}