import { clear_token, get_token, get_user_details } from "@/router";
import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import ReportCommentModal from "@/components/ReportCommentModal/ReportCommentModal.vue";
import ExchangeHubCommentModal from "@/components/ExchangeHubCommentModal/ExchangeHubCommentModal.vue";
import AllPortals from "@/components/GetPortals/PortalsComponent.vue"
import AllTopics from "@/components/GetTopics/TopicsComponent.vue"
import FloatingButton from "@/components/FloatingButton/FloatingButtonComponent.vue"
import Swal from 'sweetalert2';
import StickyNav from "@/components/BottomNavbar/StickyNavbar.vue"

export default {
  components: {
    ReportCommentModal,
    ExchangeHubCommentModal,
    HomeNavBar,
    AllPortals,
    AllTopics,
    FloatingButton,
    StickyNav

  },

  mounted() {
    this.fetchPostsByTopic()
    this.token_exists = get_token();
    this.setupTextareaAutoResize();
    this.user_id = this.get_user_id();
    this.getReportLikes();
    this.getExchangeHubLikes();
    document.addEventListener('click', this.handleClickOutside);
    this.user_details=get_user_details();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  props: ['topic_id'],

  data() {
    return ({
      reports: [],
      topics: [],
      topic: '',
      description: '',
      upload_media_hub: '',
      topic_id: '',
      exchange_hubs: {},
      upload_media: null,
      isLoading: false,
      token_exists: false,
      user_id: null,
      user_details: {
        "user_role": '',
        "first_name": '',
        "last_name": '',
      },
      report_data: {},
      topic_name: '',
      isSubmitting: false,
      errors: {},
      exchange_hub_data: [],
      comment_modal_visible: false,
      xHub_comment_modal_visible: false,
      expandedReports: [],
      expandedHub: [],
      reportLikes: {},
      feed_toggle: true,
      voice_toggle: false,
      likedExchangeHubs: [],
    });
  },

  methods: {
    closeMenu(reportId) {
      const checkbox = document.getElementById('menu-open-' + reportId);
      if (checkbox) {
        checkbox.checked = false;
      }
    },
    closeFeedMenu(exchange_hubID) {
      const checkbox = document.getElementById('menu-open-' + exchange_hubID);
      if (checkbox) {
        checkbox.checked = false;
      }
    },
    handleClickOutside(event) {
      const menus = document.querySelectorAll('.menu-open');
      menus.forEach(menu => {
        if (menu.checked && !menu.closest('.menu').contains(event.target)) {
          menu.checked = false;
        }
      });
    },
    baseUrl(post, type) {
      const baseUrl = `${window.location.origin}${this.$route.path}`;

      // Dynamically build the URL based on the type (either 'hub' or 'report')
      if (type === 'exchange_hub') {
        return `${baseUrl}?hub_id=${post.id}`;
      } else if (type === 'report') {
        return `${baseUrl}?report_id=${post.id}`;
      }
      return baseUrl
    },

    getIdFromURL(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },

    createHub() {
      this.errors = {};

      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('name', this.topic);
      formData.append('topic', this.topic_id);
      formData.append('creater', this.user_id);
      formData.append('description', this.description);


      if (this.upload_media_hub) {
        formData.append('document', this.upload_media_hub);
      }

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_exchange_hub_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: () => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Voice Created',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.resetForm();
            window.location.reload();
          });
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Something went wrong. Please try again.',
          });
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },

    fetchPostsByTopic(topicId) {
      this.topic_id = topicId;
      this.fetchReports();
      this.fetchExchangeHubs();
      this.fecthTopics();
    },
    fetchExchangeHubs() {
      this.isLoading = true;
      const topicId = this.topic_id;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_exchange_hubs_api/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { topic_id: topicId },
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.exchange_hub_data = response.exchange_hubs

            const hub_id = this.getIdFromURL('hub_id');
            if (hub_id) {
              const exchangeHub = this.exchange_hub_data.find(hub => hub.id === parseInt(hub_id, 10));
              if (exchangeHub) {
                this.display_xHub_comment(exchangeHub);
              }
            }


            this.exchange_hub_data.forEach(exchangeHub => {
              if (exchangeHub && exchangeHub.id) {
                this.getExchangeHubLikes(exchangeHub);
              }
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },
    deleteExchangeHub(exchange_hub_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: 'DELETE',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_exchange_hub_api/`,
            data: { exchange_hub_id: exchange_hub_id },
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: () => {
              Swal.fire(
                {
                  icon: 'success',
                  title: 'Voice deleted',
                  showConfirmButton: false,
                  timer: 2000
                }
              );
              window.location.reload();
            },
            error: () => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to delete the voice. Please try again.',
              });
            }
          });
        }
      });
    },

    deleteReport(report_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: 'DELETE',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_report_api/`,
            data: { report_id: report_id },
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: () => {
              Swal.fire(
                {
                  icon: 'success',
                  title: 'Feed deleted',
                  showConfirmButton: false,
                  timer: 2000
                }
              );
              window.location.reload();
            },
            error: () => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to delete the feed. Please try again.',
              });
            }
          });
        }
      });
    },
    setVisibility(isPublic) {
      this.is_public = isPublic;
    },

    resetForm() {
      this.report_name = '';
      this.report_url = '';
      this.topic = '';
      this.description = '';
      this.upload_media = null;
      this.is_public = true;
      this.errors = {};
    },

    getToken() {
      return get_token();
    },
    toggleFeedInput() {
      this.feed_toggle = true;
      this.voice_toggle = false;
    },
    toggleVoiceInput() {
      this.voice_toggle = true;
      this.feed_toggle = false;
    },

    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
    },
    handleFileUpload(event) {
      this.upload_media = event.target.files[0];
    },

    logout_user() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to logout?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log out!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          clear_token();
          window.location.reload();
        }
      });
    },
    fecthTopics() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_topics_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { portal_id: this.$route.params.portal_id },
        beforeSend: function () {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
        },
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.topics = response.topics
            Swal.close()
          }

        },

        complete: () => {
          this.isLoading = false;
          Swal.close()
        }
      });
    },

    fetchReports() {
      this.isLoading = true;
      const topicId = this.topic_id;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_report_api/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { topic_id: topicId },
        success: (data) => {
          var status =data.status
          if(status === "success"){
            this.reports = data.report_data

            const reportId = this.getIdFromURL('report_id');
            if (reportId) {
              const report = this.reports.find(report => report.id === parseInt(reportId,10));
              if (report) {
                this.display_comment_modal(report);
              }
            }
            this.reports.forEach(report => {
              if (report && report.id) {
                this.getReportLikes(report);
              }
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    filterReports() {

      this.isLoading = true;

      const portal_id = this.$route.params.portal_id

      const payload = {
        filter_values: {
          topic__portal_id: portal_id
        }
      }

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/filter_reports_api/`,
        method: 'POST',
        headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload),
        success: (response) => {
          
          const status = response.status

          if(status === "success") {
          
            this.reports =  response.data.report_data;

            const reportId = this.getIdFromURL('report_id');
            if (reportId) {
              const report = this.reports.find(report => report.id === parseInt(reportId,10));
              if (report) {
                this.display_comment_modal(report);
              }
            }
            this.reports.forEach(report => {
              if (report && report.id) {
                this.getReportLikes(report);
              }
            });
          }
          
        },
        complete: () => {
            this.isLoading = false;
        }
      });
    },
    submit_form() {
      this.errors = {};

      if (!(this.validateForm())) {
        return;
      }

      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('report_name', this.report_name);
      if (this.report_url) {
        formData.append('report_link', this.report_url);
      }
      formData.append('topic_id', this.topic_id);
      formData.append('description', this.description);
      formData.append('is_public', this.is_public);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_report_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          Swal.close();
          const status = response.status;

          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Feed Created',
              showConfirmButton: false,
              timer: 2000,

            }).then(() => {
              window.location.reload();
            });
            $('#create_report').hide();
          }
        },
        error: () => {
          Swal.close();
          this.isLoading = false;
        },
        complete: () => {
          this.isSubmitting = false;
        }
      })
    },
    async validateForm() {
      this.errors = {};
      let isValid = true;

      if (this.isReportNameExists(this.report_name)) {
        this.errors.report_name = 'Feed name already exists.';
        isValid = false;
      }

      if (this.report_url && this.report_url.trim()) {
        try {
          new URL(this.report_url);
        } catch (_) {
          this.errors.report_url = 'Please enter a valid URL.';
          isValid = false;
        }
      }

      return isValid;
    },
    isReportNameExists(name) {
      return this.reports.some(report => report.name.toLowerCase() === name.toLowerCase());
    },
    display_comment_modal(data) {
      this.report_data = data;
      this.comment_modal_visible = true;
    },
    display_xHub_comment(exchange_hubs) {
      this.exchange_hubs = exchange_hubs;
      this.xHub_comment_modal_visible = true;
    },
    hide_comment_comment() {
      this.report_data = {};
      this.comment_modal_visible = false;
    },
    hide_xhub_comment_comment() {
      this.exchange_hubs = {};
      this.xHub_comment_modal_visible = false;
    },
    setupTextareaAutoResize() {
      const reportTextarea = this.$refs.reportTextarea;
      if (reportTextarea) {
        this.autoResizeTextarea(reportTextarea);
        reportTextarea.addEventListener('input', () => this.autoResizeTextarea(reportTextarea));
      }
    },
    autoResizeTextarea(textarea) {
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    handleSubmit() {
      if (this.validateTopicForm()) {
        this.isSubmitting = true;

        const requestData = {
          topic_name: this.topic_name,
          portal_id: this.$route.params.portal_id
        };
        $.ajax({
          type: "POST",
          url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_topic_api/`,
          contentType: "application/json",
          headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(requestData),

          success: (response) => {
            const status = response.status;
            if (status === "success") {
              Swal.fire({
                icon: 'success',
                title: 'Segment Created Successfully',
                text: 'Your Segment has been created.',
                showConfirmButton: false,
                timer: 1000
              }).then(() => {
                window.location.reload();
                $('#create_topic').modal('hide');
              });
            } else if (status === "error") {
              Swal.fire({
                icon: 'error',
                title: 'Creation Failed',
                text: response.message || 'An error occurred. Please try again.',
                confirmButtonText: 'Retry'
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Unexpected Response',
                text: 'The server returned an unexpected response.',
                confirmButtonText: 'Okay'
              });
            }
          },
          error: (response) => {
            const response_data = response.responseJSON;
            if (response_data.status == "error") {
              this.errors.topic_name = response_data.message
            }
          },
          complete: () => {
            this.isSubmitting = false;
          }
        });
      }
    },

    validateTopicForm() {
      this.errors = {};
      let isValid = true;
      if (!this.topic_name.trim()) {
        this.errors.topic_name = 'Segment name is required.';
        isValid = false;
      }
      return isValid;
    },
   
 
    truncateDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedReportDescription(report) {
      const description = report.description;

      if (this.isReportExpanded(report.id)) {
        return description;
      }
  
      return this.truncateDescription(description);
    },
  
    toggleReportReadMore(reportId) {
      const index = this.expandedReports.indexOf(reportId);
      if (index === -1) {
        this.expandedReports.push(reportId);
      } else {
        this.expandedReports.splice(index, 1);
      }
    },
  
    isReportExpanded(reportId) {
      return this.expandedReports.includes(reportId);
    },
  
    shouldShowReadMore(report) {
      const description = report.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },

    truncateHubDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedHubDescription(exchange_hub) {
      const description = exchange_hub.description;
  
      if (this.isHubExpanded(exchange_hub.id)) {
        return description;
      }
  
      return this.truncateHubDescription(description);
    },
  
    toggleHubReadMore(hubId) {
      const index = this.expandedHub.indexOf(hubId);
      if (index === -1) {
        this.expandedHub.push(hubId);
      } else {
        this.expandedHub.splice(index, 1);
      }
    },
  
    isHubExpanded(hubId) {
      return this.expandedHub.includes(hubId);
    },

    showReadMore(exchange_hub) {
      const description = exchange_hub.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },


    likeReport(report) {
      const reportId = report.id;
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_report_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ report_id: reportId }),

        success: (response) => {
          const liked_report = this.reports.find(like => like.id === reportId);
          if (liked_report) {
            liked_report.like_count++;
          }
          if (response.status === "success") {
            const likeButton = document.getElementById(`like-button${reportId}`);
            const likedButton = document.getElementById(`liked-button${reportId}`);
            if (likeButton) {
              likeButton.classList.add('d-none');
            }

            if (likedButton) {
              likedButton.classList.remove('d-none');
            }
          }
        }
      });
    },
    getReportLikes(report) {
      if (!report || !report.id) {
        return;
      }

      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/retrieve_report_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { report_id: report.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.data;
            report.like_count = likes.length;
            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const likeButton = document.getElementById(`like-button${report.id}`);
            const likedButton = document.getElementById(`liked-button${report.id}`);

            if (likeButton) {
              likeButton.classList.toggle('d-none', isLiked);
            }

            if (likedButton) {
              likedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },
    unlikeReport(report) {
      const reportId = report.id;
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_report_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ report_id: reportId }),
        success: (response) => {
          if (response.status === "success") {
            const likeButton = document.getElementById(`like-button${reportId}`);
            const likedButton = document.getElementById(`liked-button${reportId}`);

            const unliked_report = this.reports.find(like => like.id === report.id);
            if (unliked_report) {
              unliked_report.like_count--;
            }

            if (likeButton) {
              likeButton.classList.remove('d-none');
            }

            if (likedButton) {
              likedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    likeExchangeHub(exchangeHub) {
      const exchangeHubId = exchangeHub.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_exchange_hub_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ exchangehub_id: exchangeHubId }),
        success: (response) => {
          if (response.status === "success") {

            exchangeHub.like_count++;
            const reactButton = document.getElementById(`react-button${exchangeHubId}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHubId}`);

            if (reactButton) {
              reactButton.classList.add('d-none');
            }

            if (reactedButton) {
              reactedButton.classList.remove('d-none');
            }
          }
        }
      });
    },
    unlikeExchangeHub(exchangeHub) {
      const exchangeHubId = exchangeHub.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_exchange_hub_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ exchangehub_id: exchangeHubId }),
        success: (response) => {
          if (response.status === "success") {
            exchangeHub.like_count--;
            const reactButton = document.getElementById(`react-button${exchangeHubId}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHubId}`);

            if (reactButton) {
              reactButton.classList.remove('d-none');
            }

            if (reactedButton) {
              reactedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    getExchangeHubLikes(exchangeHub) {
      if (!exchangeHub || !exchangeHub.id) {
        return;
      }

      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/retrieve_exchange_hub_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { exchangehub_id: exchangeHub.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.exchange_hub_likes;
            exchangeHub.like_count = likes.length;

            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const reactButton = document.getElementById(`react-button${exchangeHub.id}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHub.id}`);

            if (reactButton) {
              reactButton.classList.toggle('d-none', isLiked);
            }

            if (reactedButton) {
              reactedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },
    fetchPostsByPortal(reports) {
      this.reports = reports;
    },
    validateTopicForm() {
      this.errors = {};
      let isValid = true;
      if (!this.topic_name.trim()) {
        this.errors.topic_name = 'Segment name is required.';
        isValid = false;
      }
      return isValid;
    },
    toggleReadMore(reportId) {
      const index = this.expandedReports.indexOf(reportId);
      if (index === -1) {
        this.expandedReports.push(reportId);
      } else {
        this.expandedReports.splice(index, 1);
      }
    },
    readMore(exchange_hubID) {
      const index = this.expandedHub.indexOf(exchange_hubID);
      if (index === -1) {
        this.expandedHub.push(exchange_hubID);
      } else {
        this.expandedHub.splice(index, 1);
      }
    },
    togglePinReport(report) {
      const newPinStatus = !report.is_pinned;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/pin_report_api/`,
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          post_id: report.id,
          is_pinned: newPinStatus
        }),
        success: (response) => {
          if (response.status === 'success') {
            report.is_pinned = newPinStatus;

            const index = this.reports.indexOf(report);
            if (index > -1) {
              this.reports.splice(index, 1);
            }
            if (newPinStatus) {
              this.reports.unshift(report);
            } else {
              this.fetchReports()
            }
      }
        },
      });
    },
  },
};
