<nav class="navbar navbar-expand-md navbar-light bg-white topbar shadow-sm fixed-bottom d-lg-none">
  <ul class="navbar-nav d-flex flex-row justify-content-center w-100 text-xs fw-500">
    <li v-if="!is_group_page" class="nav-item d-flex flex-column mx-3"> <!-- Increased mx spacing -->
      <a class="rounded-circle p-1 text-primary" data-bs-toggle="offcanvas" href="#portals" role="button" aria-controls="portals_label">
        <i class='bx bx-command fs-6'></i>
      </a>
      <span class="text-xxs text-center">Portals</span>
    </li>
    <li v-if="!isHomePage && !is_group_page" class="nav-item d-flex flex-column mx-3">
      <a class="rounded-circle text-center text-primary" data-bs-toggle="offcanvas" href="#topics" role="button" aria-controls="topics_label">
        <i class='bx bxs-bulb fs-5'></i>
      </a>
      <span class="text-xxs text-center">Segments</span>
    </li>
    <li v-if="!isPortalPage && !is_group_page"  class="nav-item d-flex flex-column mx-3">
      <a class="rounded-circle p-1  text-center text-primary" data-bs-toggle="offcanvas" href="#community_assets" role="button" aria-controls="assets_label">
        <i class='bx bx-glasses fs-6'></i>
      </a>
      <span class="text-xxs text-center">Community assets</span>
    </li>
    <li v-if="!isPortalPage" class="nav-item d-flex flex-column mx-3 text-center">
      <a class="rounded-circle p-1 text-primary" data-bs-toggle="offcanvas" href="#groups" role="button" aria-controls="groups_label">
        <i class='bx bx-group fs-6'></i>
      </a>
      <span class="text-xxs text-center">Groups</span>
    </li>
    <li v-if="!isPortalPage && !isHomePage" class="nav-item d-flex flex-column mx-3 text-center">
      <a class="rounded-circle p-1 text-primary" data-bs-toggle="offcanvas" href="#group_members" role="button" aria-controls="group_members_label">
        <i class='bx bx-group fs-6'></i>
      </a>
      <span class="text-xxs text-center">Group Members</span>
    </li>
    
  </ul>
  
  </nav>
  