import Swal from 'sweetalert2';
import { get_token } from "@/router";

export default {
  data() {
    return ({
      new_password: '',
      confirm_password: '',
      errors: {},
      isLoading: false,
      newPasswordVisible: false,
      confirmPasswordVisible: false
    })
  },
  methods: {
    getToken() {
      const token = get_token();
      return token;
    },

    togglePasswordVisibility(field) {
      if (field === 'new_password') {
        this.newPasswordVisible = !this.newPasswordVisible;
      } else if (field === 'confirm_password') {
        this.confirmPasswordVisible = !this.confirmPasswordVisible;
      }
    },

    submitNewPassword() {
      if (!this.validatePasswordForm()) {
        return;
      }

      this.isLoading = true;

      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/system_management/first_time_login/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          new_password: this.new_password,
          confirm_password: this.confirm_password
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            Swal.fire({
              icon: 'success',
              text: 'Your password has been changed successful.',
              showConfirmButton: false,
              timer: 1000 
            }).then(() => {
              this.$router.push('/');
            });
          } else {
            this.errors = data;
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while processing. Please try changing password again'
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    validatePasswordForm() {
      this.errors = {};
      let isValid = true;

      if (!this.new_password.trim()) {
        this.errors.new_password = 'New password is required.';
        isValid = false;
      } else if (this.new_password.length < 8) {
        this.errors.new_password = 'New password must be at least 8 characters long.';
        isValid = false;
      } else if (!/[A-Z]/.test(this.new_password)) {
        this.errors.new_password = 'New password must contain at least one uppercase letter.';
        isValid = false;
      } else if (!/[a-z]/.test(this.new_password)) {
        this.errors.new_password = 'New password must contain at least one lowercase letter.';
        isValid = false;
      } else if (!/[0-9]/.test(this.new_password)) {
        this.errors.new_password = 'New password must contain at least one number.';
        isValid = false;
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.new_password)) {
        this.errors.new_password = 'New password must contain at least one special character.';
        isValid = false;
      }

      if (this.new_password !== this.confirm_password) {
        this.errors.confirm_password = 'Passwords do not match.';
        isValid = false;
      }

      return isValid;
    }
  }
};