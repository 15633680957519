import { get_token} from "@/router";
import Swal from 'sweetalert2';

export default {
    mounted() {
      this.fetchGroups();
      this.token_exists = get_token();
    },
  
    data() {
      return ({
        groups:[],
        group_name:'',
        group_description:'',
        is_public:true,
        isLoading: false,
        token_exists: false,
        errors:{},
      });
    },
    methods:{
        getToken() {
            return get_token();
          },
        fetchGroups() {
            this.isLoading = true;
            $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_group/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            success: (data) => {
                
                this.groups = data.groups;
            },
            complete: () => {
                this.isLoading = false;
            }
            });
        },
        createGroup() {
          if (!this.handle_unauthenticated()) {
            return;
        }
              this.isSubmitting = true;
              const Data = {
                name: this.group_name,
                is_public:this.is_public
              };
              if (this.group_description.trim()) {
                Data.description = this.group_description;
              }
      
              $.ajax({
                type: "POST",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group/`,
                contentType: "application/json",
                headers: {
                  'Authorization': `Token ${this.getToken()}`,
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify(Data),
                success: (response) => {
                  const status = response.status;
                  if (status === "success") {

                    Swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: 'Group Created Successfully',
                      showConfirmButton: false,
                      timer: 2000
                    }).then(() => {
                      window.location.reload();
                      $('#create_group_modal').modal('hide');
                    });
                  }
                },
                error: (response) => {
                  const response_data = response.responseJSON;
                  if (response_data.status==="error") {
                    this.errors.group_name =response_data.message
                  }
                },
                complete: () => {
                  this.isSubmitting = false;
                }
              });

          },

        handle_unauthenticated() {
            if (!this.token_exists) {
                this.$router.push({ name: 'sign_in_finale'}); 
              return false;
            }
            return true;
          },
        open_group(group_id,group_name,created_by) {
              if (!this.handle_unauthenticated()) {
                return;
              }
              this.$router.push({ name: 'view_group',params: {group_id: group_id,group_name:group_name,created_by}}); 
        },
       
    }
}