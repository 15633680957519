import { get_token } from "@/router";
import Swal from 'sweetalert2';
const GLOBAL_URL = process.env.VUE_APP_BACKEND_API_URL;

export default {

    name: "ExchangeHubCommentModal",

    props: {
        title: {
            type: String,
            default: "Modal Title",
        },
        exchange_hubs: {
            type: Map,
        },
    },

    data() {
        var data = {
            headers: {},
            comments_data: [],
            editing_comment_id: null,
            editing_comment_text: "",
            expandedHub: [],
            expandedHubComments: [],
            user_id:null,
            edit_reply_text:"test",
            comment_count: 0,
        };

        return data;
    },

    async mounted() {

        this.user_id = this.get_user_id();
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: `Token ${get_token()}`
        };

        this.show_modal();
        this.get_comments(1);
    },

    methods: {
        toggleZoom() {
            this.isZoomed = !this.isZoomed;
          },
          toggleFullscreen() {
            const image = this.$refs.image;
            
            // Check if fullscreen is already active
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
              // Request fullscreen for the image element (using the correct method for various browsers)
              if (image.requestFullscreen) {
                image.requestFullscreen();
              } else if (image.mozRequestFullScreen) { /* Firefox */
                image.mozRequestFullScreen();
              } else if (image.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                image.webkitRequestFullscreen();
              } else if (image.msRequestFullscreen) { /* IE/Edge */
                image.msRequestFullscreen();
              }
            } else {
              // Exit fullscreen
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
              }
            }
          },

        get_user_id() {
            const user_id = localStorage.getItem('user_id') || '';
            return user_id;
        },
        getToken() {
            return get_token();
        },
        show_modal() {
            $("#comment_modal").modal("show");
        },

        hide_modal() {
            $("#comment_modal").modal("hide");
            this.$emit('hide_xhub_comment_comment')
        },

        async get_comments(page_number) {

            var request_data = {
                "exchangehub_id": this.exchange_hubs.id,
                "page_number": page_number,
            }

            var request_url = `${GLOBAL_URL}/portal_management/retrieve_exchange_hub_comments/`;

            $.ajax({
                url: request_url,
                method: 'GET',
                headers: this.headers,
                data: request_data,
                success: (response) => {

                    if (response.status === "success") {
                        var response_data = response.data;
                        this.comments_data = response_data.comments_data.queryset;
                        this.comment_count = response_data.comments_data.count;
                    }

                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        },

        async submit_comment() {

            var comment_text = $("#comment_text_area").val();

            if (comment_text.trim() === "") {
                Swal.fire({
                    icon: 'error',
                    title: 'Please write a comment before submitting.',
                    timer: 2000,
                    showConfirmButton: false,
                });
                return;
            }

            var request_data = {
                "exchangehub_id": this.exchange_hubs.id,
                "comment_text": comment_text,
            };

            var request_url = `${GLOBAL_URL}/portal_management/add_exchange_hub_comment/`;

            $.ajax({
                url: request_url,
                method: 'POST',
                headers: this.headers,
                data: JSON.stringify(request_data),
                success: (response) => {

                    if (response.status === "success") {
                        var response_data = response.data;
                        this.comments_data.push(response_data.comment_data);
                        this.exchange_hubs.total_comments += 1;

                        $("#comment_text_area").val("");
                        return;
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Could not add comment',
                        text: 'Please try again later.',
                        timer: 3000,
                        showConfirmButton: false,
                    });

                },
                error: () => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Could not add comment',
                        text: 'Please try again later.',
                        timer: 3000,
                        showConfirmButton: false,
                    });
                }
            });

        },
        edit_exchangehub_comment(comment_data) {
            this.editing_comment_id = comment_data.id;
            this.editing_comment_text = comment_data.comment_text;
        },

        save_edit_comment(comment_id) {

            const data = {
                exchangehub_comment_id: comment_id,
                comment_text: this.editing_comment_text
            };
            $.ajax({
                type: 'PUT',
                url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/edit_exchange_hub_comment/`,
                headers: this.headers,
                contentType: 'application/json',
                data: JSON.stringify(data),
                success: (response) => {
                    if (response.status === "success") {
                        const comment = this.comments_data.find(comment => comment.id === comment_id);
                        comment.comment_text = this.editing_comment_text;
                        this.editing_comment_id = null;
                        this.editing_comment_text = "";
                    }
                },

            });
        },
        delete_exchangehub_comment(exchangehub_comment_id) {
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes,delete it'
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        type: "DELETE",
                        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_exchange_hub_comment/`,
                        data: JSON.stringify({ exchangehub_comment_id: exchangehub_comment_id }),
                        contentType: 'application/json',
                        headers: this.headers,
                        success: (response) => {
                            if (response.status === "success") {
                                Swal.fire({
                                    title: 'Deleted!',
                                    text: 'The comment has been deleted.',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1000
                                })
                                .then(() => {
                                    this.get_comments(1);
                                    this.exchange_hubs.total_comments -= 1;
                                });
                            } else {
                                Swal.fire(
                                    'Failed!',
                                    response.message || 'An error occurred. Please try again.',
                                    'error'
                                );
                            }
                        },

                    });
                }
            });
        },



        async get_comment_replies(comment_id, page_number) {
            var request_data = {
                "exchangehub_comment_id": comment_id,
                "page_number": page_number,
            };
        
            var request_url = `${GLOBAL_URL}/portal_management/retrieve_exchange_hub_comment_replies/`;
        
            $.ajax({
                url: request_url,
                method: 'GET',
                headers: this.headers,
                data: request_data,
                success: (response) => {
                    if (response.status === "success") {
                        var response_data = response.data;
                        var comment_replies = response_data.replies_data.queryset;
        
                        const reply_container = $(`#reply_section_${comment_id}`);
                        reply_container.empty();
        
                        const repliesToShow = 2;
                        comment_replies.forEach((comment_reply, index) => {
                            var reply_template = $("#reply_template").clone();
                            reply_template.attr("id", `reply_${comment_reply.id}`);
        
                            // Fill the reply content
                            const reply_time = comment_reply.time;
                            const reply_text = comment_reply.reply;
                            const reply_last_name =  comment_reply.user.last_name;
                            const reply_name = comment_reply.user.first_name;
                            const reply_user_id = comment_reply.user.id;
                            const logged_in_user_id = this.user_id;
        
                            const textLength = reply_text.length;
                            const maxLength = 25;
        
                            reply_template.find(".reply_last_name").text(reply_last_name);
                            reply_template.find(".reply_time").text(reply_time);
                            reply_template.find(".reply_Name").text(reply_name);
        
                            // Add "Read More" and "Read Less" functionality for individual replies
                            if (textLength > maxLength) {
                                const readMoreTemplate = $("#read_more_template").clone();
                                readMoreTemplate.removeClass("d-none");
                                reply_template.find(".reply_text_container").append(readMoreTemplate);
        
                                reply_template.find(".comment_text").text(reply_text.substring(0, maxLength) + "...");
                                readMoreTemplate.find('.read-more-button').on('click', function() {
                                    reply_template.find(".comment_text").text(reply_text);
                                    $(this).addClass('d-none');
                                    readMoreTemplate.find('.read-less-button').removeClass('d-none');
                                });
        
                                readMoreTemplate.find('.read-less-button').on('click', function() {
                                    reply_template.find(".comment_text").text(reply_text.substring(0, maxLength) + "...");
                                    $(this).addClass('d-none');
                                    readMoreTemplate.find('.read-more-button').removeClass('d-none');
                                });
                            } else {
                                reply_template.find(".comment_text").text(reply_text);
                            }
        
                            // Edit and delete reply actions
                            reply_template.find(".edit_reply").click(() => {
                                reply_template.find(".edit_reply_input").removeClass("d-none");
                                reply_template.find(".edit_reply_text").val(comment_reply.reply);
                                reply_template.find(".save_edit_reply").on("click", () => {
                                    var reply_text = reply_template.find(".edit_reply_text").val();
                                    let editing_comment_id = comment_reply.id;
        
                                    this.save_edit_reply(editing_comment_id, reply_text);
                                    reply_template.find(".comment_text").text(reply_text);
                                    reply_template.find(".edit_reply_input").addClass("d-none");
                                });
                            });
        
                            reply_template.find(".delete_reply").on("click", () => {
                                this.deleteexchangehubReply(comment_reply.id);
                            });
        
                            // Toggle actions based on user id
                            if (String(reply_user_id) === String(logged_in_user_id)) {
                                reply_template.find(".reply_dropdown").removeClass("d-none");
                            } else {
                                reply_template.find(".reply_dropdown").addClass("d-none");
                            }
        
                            reply_template.removeClass("d-none");
        
                            if (index < repliesToShow) {
                                reply_template.addClass('show-reply');
                            } else {
                                reply_template.addClass('hidden-reply d-none');
                            }
        
                            reply_container.append(reply_template);
                        });
        
                        // Add "Read More" and "Read Less" functionality for replies
                        if (comment_replies.length > repliesToShow) {
                            var read_more_template = $("#read_more_template").clone();
                            read_more_template.removeClass("d-none");
        
                            read_more_template.find('.read-more-button').on('click', function() {
                                reply_container.find('.hidden-reply').removeClass('d-none');
                                $(this).addClass('d-none');
                                read_more_template.find('.read-less-button').removeClass('d-none');
                            });
        
                            read_more_template.find('.read-less-button').on('click', function() {
                                reply_container.find('.hidden-reply').addClass('d-none');
                                $(this).addClass('d-none');
                                read_more_template.find('.read-more-button').removeClass('d-none');
                            });
        
                            reply_container.append(read_more_template);
                        }
        
                        reply_container.removeClass("d-none");
                        this.toggler_comment_input(comment_id);
                    }
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        },

        toggler_comment_input(comment_id) {
            $(`#reply_toggler_section_${comment_id}`).addClass("d-none");
            $(`#reply_container_${comment_id}`).removeClass("d-none");
        },

        submit_reply(comment_id) {

            var reply_text_input = $(`#new_reply_text${comment_id}`);
            var reply_text = reply_text_input.val();

            if (reply_text.trim() === "") {
                Swal.fire({
                    icon: "error",
                    title: "Can't submit an empty reply.",
                    timer: 2000,
                    showConfirmButton: false,
                });
                return;
            }

            var request_data = {
                "reply_text": reply_text,
                "exchangehub_comment_id": comment_id,
            }

            var request_url = `${GLOBAL_URL}/portal_management/add_exchange_hub_comment_reply/`;

            $.ajax({
                url: request_url,
                method: 'POST',
                headers: this.headers,
                data: JSON.stringify(request_data),
                success: (response) => {

                    if (response.status === "success") {

                        var response_data = response.data;
                        var comment_data = response_data.comment_data;
                        const comment = this.comments_data.find(comment => comment.id === comment_id);

                        if (comment) {
                            if (!comment.replies) {
                                comment.replies = [];
                            }
                            comment.replies.push(response_data.reply_data);
                            comment.total_replies++;
                        }

                        const reply_time = comment_data.time;
                        const reply_text = comment_data.reply;
                        const reply_name = comment_data.user.first_name;

                        var reply_template = $("#reply_template").clone()

                        reply_template.find(".comment_text").text(reply_text);
                        reply_template.find(".reply_time").text(reply_time);
                        reply_template.find(".reply_Name").text(reply_name);

                        var reply_section = $(`#reply_section_${comment_id}`);

                        reply_section.append(reply_template);
                        reply_section.removeClass("d-none");
                        reply_template.removeClass("d-none");

                        reply_text_input.val("");

                    }

                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        },
        readMore(exchange_hubID) {
            const index = this.expandedHub.indexOf(exchange_hubID);
            if (index === -1) {
              this.expandedHub.push(exchange_hubID);
            } else {
              this.expandedHub.splice(index, 1);
            }
          },
          toggleReadMore(hubId) {

            const index = this.expandedHubComments.indexOf(hubId);
            if (index === -1) {
              this.expandedHubComments.push(hubId);
            } else {
              this.expandedHubComments.splice(index, 1);
            }
          },
        edit_reply(reply_data) {
            this.editing_comment_id = reply_data.id;
            this.editing_comment_text = reply_data.reply;

            $(`#edit_reply_text_area_${reply_data.id}`).val(this.editing_comment_text);
            $(`#edit_reply_section_${reply_data.id}`).removeClass("d-none");
            $('#edit_reply_modal').modal('show');
        },

        save_edit_reply(reply_id,reply_text) {
            const data = {
                reply_id: reply_id,
                reply_text: reply_text
            };

            $.ajax({
                type: 'PUT',
                url: `${GLOBAL_URL}/portal_management/edit_exchange_hub_comment_reply/`,
                headers: this.headers,
                contentType: 'application/json',
                data: JSON.stringify(data),
                success: (response) => {
                    const updatedReply = response.data.reply_data;

                    if (Array.isArray(this.comments_data)) {
                        const comment = this.comments_data.find(comment =>
                            Array.isArray(comment.replies) && comment.replies.some(reply => reply.id === updatedReply.id)
                        );

                        if (comment) {
                            const reply = comment.replies.find(reply => reply.id === updatedReply.id);
                            if (reply) {
                                reply.reply = updatedReply.reply_text;

                    $(`#reply_text_${reply.id}`).text(updatedReply.reply_text);
                    $(`#edit_reply_section_${reply_id}`).addClass("d-none");
                    $(`#edit_reply_text_area_${reply_id}`).val('');
                    $("#edit_reply_modal").modal("hide");
                }
            }
        }
                    }
                });
            },
            deleteexchangehubReply(reply_id) {
                Swal.fire({
                  title: 'Are you sure?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    $.ajax({
                      type: "DELETE",
                      url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_exchange_hub_comment_reply/`,
                      data: JSON.stringify({ reply_id: reply_id }),
                      headers: {
                        'Authorization': `Token ${this.getToken()}`,
                        'Content-Type': 'application/json'
                      },
                      success: (response) => {
                        const status = response.status;
                        if (status === "success") {
                          Swal.fire({
                            title: 'Deleted!',
                            text: 'The reply has been deleted.',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                          }).then(() => {
                            $(`#reply_${reply_id}`).remove();
                        });
                        } else {
                          Swal.fire(
                            'Failed!',
                            response.message || 'An error occurred. Please try again.',
                            'error'
                          );
                        }
                      },
                      error: () => {
                        Swal.fire(
                          'Failed!',
                          'An error occurred. Please try again.',
                          'error'
                        );
                      }
                    });
                  }
                });
              }
        }
    }
