<div class="offcanvas offcanvas-start" tabindex="-1" id="groups" aria-labelledby="groups_label">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="groups_label">Groups</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body"> 
      <table v-if="groups" class="table mb-0">
        <tbody>
          <tr v-for="group in groups" :key="group.id" class="hover-row border-0 ">
            <td class="border-0">
              <span
                class="text-decoration-none text-dark"
                @click="open_group(group.id,group.name,group.created_by)"
              >
          
                <p class="portal-icon mb-0 d-flex align-items-center">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bx-command fs-5'></i></a>
                    <span class="text-xs fw-500 mx-2">{{ group.name }}<i v-if="!group.is_public" class='bx bxs-lock text-primary text-xxs'></i></span>
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="d-none d-xl-block mt-4">
    <div class="mx-5 rounded-5 pb-1 position-relative top-54px">
      <h6 class='text-start mx-1'>Groups</h6>
      <hr />
        
        <!-- Create Group Modal -->
      <div class="table-container">
        <table v-if="groups.length" class="table mb-0">
          <tbody>
            <tr v-for="group in groups" :key="group.id" class="hover-row border-0 ">
              <td class="border-0">
                <span
                  class="text-decoration-none text-dark"
                 
                >
                  <p class="portal-icon mb-0 d-flex align-items-center" @click="open_group(group.id,group.name,group.created_by.id)">
                    <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bxs-group fs-5'></i></a>
                      <span class="text-xs fw-500 mx-2">{{ group.name }} <i v-if="!group.is_public" class='bx bxs-lock text-primary text-xxs'></i></span>
                  </p>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <p v-else class="text-secondary text-xs text-center">
          No Available Groups
        </p>
      </div>
    </div>
  </div>

<div class="modal fade" id="create_group_modal" tabindex="-1" aria-labelledby="create_group_label" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-5">
        <form @submit.prevent="createGroup">
          <div class="modal-body">
            <h6 class="modal-title mb-3" id="create_group_label">Create Group</h6>
            <div class="mb-3">
              <label for="group_name" class="form-label text-xs fw-500">Group Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control rounded-5 text-xs" id="group_name" v-model="group_name" placeholder="Enter group name">
              <p v-if="errors.group_name" class="text-danger text-xs">{{ errors.group_name }}</p>
            </div>
            <div class="mb-3">
              <label for="group_description" class="form-label text-xs fw-500">Group Description</label>
              <textarea class="form-control rounded-5 text-xs" id="group_description" v-model="group_description" placeholder="Enter group description"></textarea>
            </div>
            <div class="mb-3">
              <label for="is_public" class="form-label text-xs fw-500">Visibility <span class="text-danger">*</span></label>
              <select class="form-select rounded-5 text-xs" id="is_public" v-model="is_public" required>
                <option selected hidden>Select visibility type</option>
                <option value="true">Public</option>
                <option value="false">Private</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary text-xs rounded-5 shadow mx-1" data-bs-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary text-xs rounded-5 shadow">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  