import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import { get_token } from "@/router";

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      topic_name: '',
      isSubmitting: false,
      edit_topic_name:'',
      errors: {},
      topics: [],
      isLoading: false
    });
  },
  methods: {
    resetPortalForm()
    {
      this.topic_name = '';
    },

    getToken() {
      return get_token();
    },
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },

    handleSubmit() {
      if (this.validateTopicForm()) { 
        this.isSubmitting = true;
    
        const requestData = {
          topic_name: this.topic_name,
          portal_id: this.$route.params.portal_id
        };

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_topic_api/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(requestData),
        success: (response) => {
          
          const status = response.status;
          
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Segment Created Successfully',
              text: 'Your segment has been created.',
              showConfirmButton: false,
              timer: 1000
            }).then(() => {
              this.topics.unshift(response.topic);
              $('#create_topic').modal('hide');
            });
          } else if (status === "error") {
            Swal.fire({
              icon: 'error',
              title: 'Creation Failed',
              text: response.message || 'An error occurred. Please try again.',
              confirmButtonText: 'Retry'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Unexpected Response',
              text: 'The server returned an unexpected response.',
              confirmButtonText: 'Okay'
            });
          }
        },
        error: (response) => {
          const response_data = response.responseJSON;
          if(response_data.status=="error"){
           this.errors.topic_name=response_data.message
          }
      },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    }
   },

    validateTopicForm() {
      this.errors = {};
      let isValid = true;
      if (!this.topic_name.trim()) {
        this.errors.topic_name = 'Segment name is required.';
        isValid = false;
      }
      return isValid;
    },
    fetchTopics() {
      this.isLoading = true;
      return new Promise((resolve) => {
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_topics_api/`,
        data: {portal_id: this.$route.params.portal_id},
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          this.topics = response.topics;
          resolve();
        },
        error: () => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while fetching segments. Please try again!'
          });
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    });
    },

    deleteTopic(topic_id) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_topic_api/`,
            data: {
              topic_id: topic_id
            },
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The segment has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  this.topics = this.topics.filter(topic => topic.id !== topic_id);
                });
              } else {
                Swal.fire(
                  'Failed!',
                  response.message || 'An error occurred. Please try again.',
                  'error'
                );
              }
            },
          });
        }
      });
    },
    clearForm(){
      this.topic_name = '';
    },

    editTopicNaming(topic){
      this.edit_topic_name=topic.name
    },
    editTopic(topic) {

      var data = {
        topic_id: topic.id,
        topic_name: this.edit_topic_name
      }

      $.ajax({
        type: 'PUT',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/update_topic_api/`,

        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: (response) => {
          const status = response.status;

          if (status === "success") {
              const updatedTopic = this.topics.find(t => t.id === topic.id);
              if (updatedTopic) {
                updatedTopic.name = this.edit_topic_name;
              }
              $(`#edit_topic_${topic.id}`).modal('hide');

              $('.modal-backdrop').remove();

          this.formHidden = true;
          }
        }
      });
    }
  },
  mounted() {
    this.fetchTopics().then(() => {
      this.$nextTick(() => {
        $('.table').DataTable({
          paging: true,
          searching: true, 
          ordering: true,
          info: true,
          lengthChange: true,
          pageLength: 10,
          language: {
            paginate: {
              previous: "Previous",
              next: "Next"
            },
            info: "Showing _START_ to _END_ of _TOTAL_ entries",
            infoEmpty: "No entries available"
          }
        });
      });
    });
    const create_topic = document.getElementById('create_topic');

    create_topic.addEventListener('show.bs.modal', () => {
      this.resetPortalForm();
    });
  }
};