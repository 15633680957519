<ul v-if="token_exists" class="navbar-nav d-flex flex-row text-xs fw-500 ">
    <!-- Notification Dropdown -->
    <li class="nav-item dropdown position-relative">
      <a
        class="nav-link text-dark text-sm pb-2 me-1"
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-bell-fill fs-4"></i>
        <!-- Notification Count Badge -->
        <span v-if="notifications.length > 0" class="position-absolute start-40  translate-middle badge rounded-pill bg-danger">
          {{ notifications.length }}
          <span class="visually-hidden">unread notifications</span>
        </span>
      </a>
      <!-- Dropdown Menu -->
      <ul class="dropdown-menu notifications-dropdown p-2 shadow border-0" aria-labelledby="navbarDropdown">
        <li class="dropdown-item-text text-center fw-bold border-bottom pb-2 mb-2">
          Notifications
        </li>
        <li v-for="notification in notifications" :key="notification.id" class="dropdown-item notification-item d-flex align-items-start text-xxs border-bottom">
          <div class="notification-content flex-grow-1 text-wrap text-xxs">
            {{ notification.message }}
          </div>
          <div class="notification-date small ms-2 text-nowrap">{{ formatDate(notification.date) }}</div>
        </li>
      </ul>
      
    </li>
  </ul>