
<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
    <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
      <div class="card-header rounded-top-5 mv-5 shadow bg-dark-purple">
        <span class="fw-500 text-white">All users</span>
          <button class="btn btn-warning text-white float-end text-xs rounded-5 shadow fw-500" data-bs-toggle="modal" data-bs-target="#create_user">
            <i class='bx bx-plus'></i> Create User
          </button>

          <div class="modal fade" id="create_user" tabindex="-1" aria-labelledby="create_userLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <p class="modal-title text-xs fw-500" id="create_user_label">Create User</p>
                  <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="submit_form">
                  <div class="modal-body">
          
                    <div class="mb-3">
                      <label for="first_name" class="form-label text-xs fw-500">First Name <span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="first_name" placeholder="Enter first name"
                        v-model="first_name">
                        <p v-if="errors.first_name" class="text-danger text-xs">{{ errors.first_name }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="last_name" class="form-label text-xs fw-500">Last Name <span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="last_name" placeholder="Enter last name"
                        v-model="last_name">
                        <p v-if="errors.last_name" class="text-danger text-xs">{{ errors.last_name }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="phone_number" class="form-label text-xs fw-500">Phone Number<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="phone_number" placeholder="Enter phone number"
                        v-model="phone_number">
                        <p v-if="errors.phone_number" class="text-danger text-xs">{{ errors.phone_number }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="email" class="form-label text-xs fw-500">Email<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="email" placeholder="example@gmail.com"
                        v-model="email">
                        <p v-if="errors.email" class="text-danger text-xs">{{ errors.email }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="user_role" class="form-label text-xs fw-500">User Role<span class="text-danger">*</span></label>
                      <select class="form-select rounded-5 text-xs" id="user_role" v-model="user_role">
                        <option selected hidden>Select Role</option>
                        <option v-for="role in roles" :value="role.role" :key="role.id">{{ role.role }}</option>
                      </select>
                      <p v-if="errors.user_role" class="text-danger text-xs">{{ errors.user_role }}</p>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal fade" id="edit_user" tabindex="-1" aria-labelledby="edit_user_label" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <p class="modal-title text-xs fw-500" id="edit_user_label">Edit User</p>
                  <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="update_user">
                  <div class="modal-body">
          
                    <div class="mb-3">
                      <label for="first_name" class="form-label text-xs fw-500">First Name <span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="first_name" placeholder="Enter first name"
                        v-model="edit_form.first_name">
                        <p v-if="errors.first_name" class="text-danger text-xs">{{ errors.first_name }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="last_name" class="form-label text-xs fw-500">Last Name <span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="last_name" placeholder="Enter last name"
                        v-model="edit_form.last_name">
                        <p v-if="errors.last_name" class="text-danger text-xs">{{ errors.last_name }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="phone_number" class="form-label text-xs fw-500">Phone Number<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="phone_number" placeholder="Enter phone number"
                        v-model="edit_form.phone_number">
                        <p v-if="errors.phone_number" class="text-danger text-xs">{{ errors.phone_number }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="email" class="form-label text-xs fw-500">Email<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="email" placeholder="example@gmail.com"
                        v-model="edit_form.email" readonly>
                        <p v-if="errors.email" class="text-danger text-xs">{{ errors.email }}</p>
                    </div>

                    <div class="mb-3">
                      <label for="user_role" class="form-label text-xs fw-500">User Role<span class="text-danger">*</span></label>
                      <select class="form-select rounded-5 text-xs" id="user_role" v-model="edit_form.user_role">
                        <option selected hidden>Select Role</option>
                        <option v-for="role in roles" :value="role.role" :key="role.id">{{ role.role }}</option>
                      </select>
                      <p v-if="errors.user_role" class="text-danger text-xs">{{ errors.user_role }}</p>
                    </div>
          
                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>

      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link"
            :class="{ active: active_tab === 'users' }"
            @click="setactive_tab('users')"
            id="nav-users-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-users"
            type="button"
            role="tab"
            aria-controls="nav-users"
            aria-selected="active_tab === 'users'"
          >All users</button>
          
          <button
            class="nav-link"
            :class="{ active: active_tab === 'admin' }"
            @click="setactive_tab('admin')"
            id="nav-admin-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-admin"
            type="button"
            role="tab"
            aria-controls="nav-admin"
            aria-selected="active_tab === 'admin'"
          >Admin</button>
          
          <button
            class="nav-link"
            :class="{ active: active_tab === 'client' }"
            @click="setactive_tab('client')"
            id="nav-client-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-client"
            type="button"
            role="tab"
            aria-controls="nav-client"
            aria-selected="active_tab === 'client'"
          >Client</button>
          
          <button
            class="nav-link"
            :class="{ active: active_tab === 'customer' }"
            @click="setactive_tab('customer')"
            id="nav-customer-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-customer"
            type="button"
            role="tab"
            aria-controls="nav-customer"
            aria-selected="active_tab === 'customer'"
          >Customer</button>
          
          <button
            class="nav-link"
            :class="{ active: active_tab === 'stakeholder' }"
            @click="setactive_tab('stakeholder')"
            id="nav-stakeholder-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-stakeholder"
            type="button"
            role="tab"
            aria-controls="nav-stakeholder"
            aria-selected="active_tab === 'stakeholder'"
          >Stakeholder</button>
          
          <button
            class="nav-link"
            :class="{ active: active_tab === 'editor' }"
            @click="setactive_tab('editor')"
            id="nav-editor-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-editor"
            type="button"
            role="tab"
            aria-controls="nav-editor"
            aria-selected="active_tab === 'editor'"
          >Editor</button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">
          
          
          <div class="card-body table-responsive">
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in filteredUsers" :key="user.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone_number }}</td>
                  
                  
                  <td>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="edit_user(user)"v-if="user.id != logged_in_user_id"><i class="bi bi-pencil-square"></i> Edit</button>
                    <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_deactivation(user.id)"  v-if="user.is_active && user.id != logged_in_user_id">Deactivate</button>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_activation(user.id)"  v-if="!user.is_active && user.id != logged_in_user_id">Activate</button>  
                    <button
                      class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm d-none"
                      @click="delete_user(user.id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>      
          
          
        </div>
        <div class="tab-pane fade" id="nav-admin" role="tabpanel" aria-labelledby="nav-admin-tab">
          
          <div class="card-body table-responsive">
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in  filteredAdmin" :key="user.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone_number }}</td>
                  


                  <td>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="edit_user(user)" v-if="user.id != logged_in_user_id"><i class="bi bi-pencil-square"></i> Edit</button>
                    <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_deactivation(user.id)" v-if="user.is_active && user.id != logged_in_user_id">Deactivate</button>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_activation(user.id)" v-if="!user.is_active && user.id != logged_in_user_id">Activate</button>  
                    <button
                      class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm d-none"
                      @click="delete_user(user.id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>      

          
          
        </div>
        <div class="tab-pane fade" id="nav-client" role="tabpanel" aria-labelledby="nav-client-tab">

          <div class="card-body table-responsive">
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Action</th>
                  
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in  filteredClient" :key="user.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone_number }}</td>
                  


                  <td>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="edit_user(user)" v-if="user.id != logged_in_user_id"><i class="bi bi-pencil-square"></i> Edit</button>
                    <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_deactivation(user.id)" v-if="user.is_active && user.id != logged_in_user_id">Deactivate</button>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_activation(user.id)"v-if="!user.is_active && user.id != logged_in_user_id">Activate</button>  
                    <button
                      class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm"
                      @click="delete_user(user.id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>      



        </div>

        <div class="tab-pane fade" id="nav-customer" role="tabpanel" aria-labelledby="nav-customer-tab">

          <div class="card-body table-responsive">
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Action</th>
                  
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in filteredCustomer" :key="user.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone_number }}</td>
                  


                  <td>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="edit_user(user)" v-if="user.id != logged_in_user_id"><i class="bi bi-pencil-square"></i> Edit</button>
                    <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_deactivation(user.id)" v-if="user.is_active">Deactivate</button>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_activation(user.id)" v-else>Activate</button>  
                    <button
                      class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm d-none"
                      @click="delete_user(user.id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>      



        </div>

        <div class="tab-pane fade" id="nav-stakeholder" role="tabpanel" aria-labelledby="nav-stakeholder-tab">


          <div class="card-body table-responsive">
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Action</th>
                  
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in  filteredStakeholder" :key="user.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone_number }}</td>
                  


                  <td>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="edit_user(user)" v-if="user.id != logged_in_user_id"><i class="bi bi-pencil-square"></i> Edit</button>
                    <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_deactivation(user.id)" v-if="user.is_active && user.id != logged_in_user_id">Deactivate</button>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_activation(user.id)" v-if="!user.is_active && user.id != logged_in_user_id">Activate</button>  
                    <button
                      class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm d-none"
                      @click="delete_user(user.id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>      


        </div>

        <div class="tab-pane fade" id="nav-editor" role="tabpanel" aria-labelledby="nav-editor-tab">


          <div class="card-body table-responsive">
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Action</th>
                  
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in filteredEditor" :key="user.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone_number }}</td>
                


                  <td>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="edit_user(user)" v-if="user.id != logged_in_user_id"><i class="bi bi-pencil-square"></i> Edit</button>
                    <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_deactivation(user.id)" v-if="user.is_active && user.id != logged_in_user_id">Deactivate</button>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="confirm_activation(user.id)" v-if="!user.is_active && user.id != logged_in_user_id">Activate</button>  
                    <button
                      class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm d-none"
                      @click="delete_user(user.id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>      


        </div>
      </div>
    </div>
  </section>
</div>