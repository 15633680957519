import { get_token } from "@/router";
import Swal from 'sweetalert2';

export default {
  mounted() {
    this.fetchcommunity_assets();
    this.get_community_assets_category();
    this.token_exists = get_token();
  },

  data() {
    return ({
      asset_name:'',
      description:'',
      location:'',
      category:'',
      category_id:'',
      file_name: '',
      upload_media: null,
      community_assets: [],
      community_asset_categories: [],
      community_asset_name: '',
      community_asset_description: '',
      community_asset_modal_visible: false,
      community_asset_data: {},
      is_public: true,
      isLoading: false,
      token_exists: false,
      errors: {},
    });
  },
  methods: {
    getToken() {
      return get_token();
    },
    fetchcommunity_assets() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_community_asset/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (data) => {

          this.community_assets = data.community_assets;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    handleFileUpload(event) {
      this.upload_media = event.target.files[0];
    },
    createcommunity_asset() {
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('name', this.asset_name);
      formData.append('description', this.description);
      formData.append('location', this.location);
      formData.append('category_id', this.category_id);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_community_asset/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: (response) => {
          const status = response.status;
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Community asset Created Successfully',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload();
              $('#create_community_asset_modal').modal('hide');
            });
          }
        },
        error: (response) => {
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.community_asset_name = response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    handle_unauthenticated() {
      if (!this.token_exists) {
        this.$router.push({ name: 'sign_in_finale' });
        return false;
      }
      return true;
    },

    display_community_asset(community_asset_data) {
      if(!this.handle_unauthenticated()){
        return;
      }
      this.community_asset_modal_visible = false;

      setTimeout(() => {
        this.community_asset_data = community_asset_data;
        this.community_asset_modal_visible = true;
      }); 
    },



  hide_community_asset() {
      this.community_asset_data = {};
      this.community_asset_modal_visible = false;
    },

    get_community_assets_category() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_community_assets_category/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          this.community_asset_categories = response.data.categories;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
  }
}