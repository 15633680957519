<section class="image-background">
  <div class="form-bg">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="form-container rounded-5 shadow p-5 top-20">
            <img :src="$clientLogo" class="img-fluid mx-auto d-block w-150" alt="">
            <h3 class="title text-center">Login</h3>
            <form @submit.prevent="handleSubmit">
              <div class="mb-3">
                <label for="email" class="form-label text-xs fw-500">Email<span class="text-danger">*</span></label>
                <input type="email" class="form-control rounded-5 text-xs p-2" placeholder="example@gmail.com" id="email" v-model="email" required>
              </div>
            
              <div class="mb-3 position-relative">
                <label for="password" class="form-label text-xs fw-500">Password<span class="text-danger">*</span></label>
                <div class="position-relative">
                  <input
                    :type="passwordVisible ? 'text' : 'password'"
                    class="form-control rounded-5 text-xs p-2 mb-1 pr-5"
                    id="password"
                    placeholder="Enter password"
                    v-model="password"
                    required
                  />
                  <span class="eye-toggle" @click="togglePasswordVisibility">
                    <i :class="passwordVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                  </span>
                </div>
                <p class="text-end text-xs ">Forgot password? <a :href="reset_url" class="text-info fw-500">Reset password</a></p>
              </div>
            
              <button type="submit" class="btn btn-primary rounded-5 shadow-sm w-100 mt-3 mb-1">Sign in</button>
              <p class="text-start text-xs">Don't have an account? <router-link to="sign_up_finale" class="text-info fw-500">Sign up</router-link></p>
            </form>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>