import { clear_token, get_token, get_user_details } from "@/router";
import Swal from 'sweetalert2';

import NotificationDropdown from '@/components/Notifications/Notification.vue'
export default {

  components: {
    NotificationDropdown
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'home'; // Assuming your home page route is named 'home'
    },
    firstNameInitial() {
      return this.userDetails.first_name.charAt(0);
    }

  },
  mounted() {
    this.token_exists = get_token();
    this.user_details = get_user_details();
    this.user_id = this.get_user_id();

  },

  data() {
    return ({
      token_exists: false,
      user_id:null,
      user_details: {
        "user_role":'',
        "first_name":'',
        "last_name":'',
      },
      showIcons: false,
    });
  },

  methods: {
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },
    logout_user() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to logout?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log out!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          clear_token();
          window.location.reload();
        }
      });
    },
    toggleIcons() {
      this.showIcons = !this.showIcons;
    },


  },
};