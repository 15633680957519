<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>

    <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
      <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
        <span class="fw-500">Community Assets</span>
        <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end " data-bs-toggle="modal"
        data-bs-target="#create_community_asset">
          <i class='bx bx-plus'></i> Create Asset
        </button>
      </div>

      <div class="modal fade" id="create_community_asset" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create Community Asset</p>
              <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createcommunity_asset">
              <div class="modal-body">
       
                <div class="mb-3">
                  <label for="asset_name" class="form-label text-xs fw-500">Community Asset Name <span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control rounded-5 text-xs" id="asset_name" placeholder="Enter community asset name"
                    v-model="asset_name">
                    <p v-if="errors.asset_name" class="text-danger text-xs">{{ errors.asset_name }}</p>
                </div>
                <div class="mb-3">
                  <label for="asset_name" class="form-label text-xs fw-500">Community Asset Location <span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control rounded-5 text-xs" id="location" placeholder="Enter community asset name"
                    v-model="location">
                    <p v-if="errors.location" class="text-danger text-xs">{{ errors.location }}</p>
                </div>
                <div class="mb-3">
                  <label for="category_id" class="form-label text-xs fw-500">Select a Category<span class="text-danger">*</span></label>
                  <select class="form-select rounded-5 text-xs" id="category_id" v-model="category_id">
                    <option selected hidden>Select a Category</option>
                    <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
                  </select>
                  <p v-if="errors.category_id" class="text-danger text-xs">{{ errors.category_id }}</p>
                </div>
                <div class="mb-3">
                  <label for="asset_name" class="form-label text-xs fw-500">Subscription fee</label>
                  <input type="text" class="form-control rounded-5 text-xs" id="subscription_fee" placeholder="R0"
                    v-model="subscription_fee">
                    <p v-if="errors.subscription_fee" class="text-danger text-xs">{{ errors.subscription_fee }}</p>
                </div>
                <QuillEditor theme="snow" v-model="description" ref="quillAssetEditorRef" class="quill-editor"
                  placeholder="Enter your description here..." toolbar="full" />
                <div class="mt-2 mx-1 border-bottom ">
                  <div class="d-flex flex-row justify-content-between">
                    <div class="file-upload">
                      <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light"><i
                          class='bx bx-image-add file-upload-icon'></i> Add asset content</label>
                      <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                    </div>
                    <div v-if="fileName" class="text-xs mt-2 text-secondary text-danger fst-italic"><span class="fw-500">File
                        name:</span>{{ fileName }}</div>
                  </div>
                </div>
       
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="card-body table-responsive">
        <table class="table table-striped text-xs mt-3">
          <thead class="bg-dark text-white">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Asset Name</th>
              <th scope="col">Asset Category</th>
              <th scope="col">Created By</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(asset, index) in community_assets" :key="asset.id">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ asset.name }}</td>
              <td>{{ asset.category.name }}</td>
              <td>{{ asset.creater.first_name }} {{ asset.creater.last_name }}</td>
              <td>

                <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" @click="openModal(asset)">
                  <i class="bi bi-pencil-square"></i> View
                </button>

                  <CommunityAssetModal
                  :community_asset_data="selectedAssetData"
                  :community_assets="community_assets"
                  v-if="isModalVisible"
                  @hide_comment_comment="isModalVisible = false"
                  />
              
                <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" data-bs-toggle="modal" :data-bs-target="'#edit_asset_' + asset.id" @click="editAssetName(asset)">
                  <i class="bi bi-pencil-square"></i> Edit
                </button>
                <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow" @click="deleteAsset(asset.id)">
                  <i class="bi bi-trash"></i> Delete
                </button>

                <!-- Edit Community Asset Modal -->
              <div class="modal fade" :id="'edit_asset_' + asset.id" tabindex="-1" aria-labelledby="editAssetModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <p class="modal-title text-xs fw-500" id="editAssetModalLabel">Edit Community Asset</p>
                      <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="updateCommunityAsset">
                      <div class="modal-body">
                        <div class="mb-3">
                          <label for="edit_asset_name" class="form-label text-xs fw-500">Community Asset Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control rounded-5 text-xs" id="edit_asset_name" v-model="edit_asset_name" required>
                        </div>
                        <div class="mb-3">
                          <label for="edit_location" class="form-label text-xs fw-500">Community Asset Location <span class="text-danger">*</span></label>
                          <input type="text" class="form-control rounded-5 text-xs" id="edit_location" v-model="edit_asset_location" required>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="submit" class="btn btn-primary text-xs rounded-5">Update Asset</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    
  </section>
</div>
