import { get_token } from "@/router";
export default {

    name: "CommunityAssetModal",

    props: {
        title: {
            type: String,
            default: "Modal Title",
        },
        community_asset_data: {
            type: Map,
        },
        community_assets: {
          type: Object,
        },
    },

    data() {
        var data = {
            headers: {},
            user_id:null,
            isZoomed: false,
            isFullscreen: false,
            asset_data: {},
            showmore: false,
            // community_assets: [],
        };

        return data;
    },

    async mounted() {
        this.user_id = this.get_user_id();
        this.asset_data = this.community_asset_data;
        this.getCommunityAssetSubscriptions(this.asset_data.id)

        this.headers = {
            'Content-Type': 'application/json',
            Authorization: `Token ${get_token()}`
        };
        
        // this.fetchcommunity_assets();
        this.show_modal();
    },
    watch: {
      community_asset_data(newData) {
        this.set_communit_asset(newData);
        this.show_modal(); }

  },
  computed: {
    shouldShowReadMore() {
      const words = this.asset_data?.description?.split(/\s+/) || [];
      const minWordsForReadMore = 60;  
      return words.length > minWordsForReadMore;
    },
  },

    methods: {
        toggleZoom() {
            this.isZoomed = !this.isZoomed;
          },
          toggleFullscreen() {
            const image = this.$refs.image;
            
            // Check if fullscreen is already active
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
              // Request fullscreen for the image element (using the correct method for various browsers)
              if (image.requestFullscreen) {
                image.requestFullscreen();
              } else if (image.mozRequestFullScreen) { /* Firefox */
                image.mozRequestFullScreen();
              } else if (image.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                image.webkitRequestFullscreen();
              } else if (image.msRequestFullscreen) { /* IE/Edge */
                image.msRequestFullscreen();
              }
            } else {
              // Exit fullscreen
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
              }
            }
          },

        get_user_id() {
            const user_id = localStorage.getItem('user_id') || '';
            return user_id;
        },
        getToken() {
            return get_token();
        },
        show_modal() {
            $("#community_asset_modal").modal("show");
        },

        hide_modal() {
            $("#community_asset_modal").modal("hide");
            this.$emit('hide_comment_comment')
        },
        set_communit_asset(community_assets) {
          this.asset_data = community_assets;
        },
        readAssetDescription(description) {
          const words = description.split(/\s+/);
          const maxLength = 60;
          return words.length > maxLength ? words.slice(0, maxLength).join(" ") + '...' : description;
        },
        toggleDescription() {
          this.showmore = !this.showmore;
        },
        subscribeCommunityAsset(communityAsset) {
          const communityAssetId = communityAsset.id;    
          $.ajax({
            type: "POST",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/subscribe_community_asset/`,
            contentType: "application/json",
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            data: JSON.stringify({ community_asset_id: communityAssetId }),
            success: (response) => {
              if (response.status === "success") {
                communityAsset.subscribed = true;

                const subscribeButton = $(`#subscribe-button${communityAssetId}`);
                const subscribedButton = $(`#subscribed-button${communityAssetId}`);

                if (subscribeButton.length) {
                  subscribeButton.addClass('d-none');
                }

                if (subscribedButton.length) {
                  subscribedButton.removeClass('d-none');
                }
              }
            },
          });
        },
        unsubscribeCommunityAsset(communityAsset) {
          const communityAssetId = communityAsset.id;
          $.ajax({
              type: "POST",
              url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/subscribe_community_asset/`,
              contentType: "application/json",
              headers: {
                  'Authorization': `Token ${this.getToken()}`,
              },
              data: JSON.stringify({ community_asset_id: communityAssetId }),
              success: (response) => {
                  if (response.status === "success") {
                      communityAsset.subscribed = false;

                      const subscribeButton = $(`#subscribe-button${communityAssetId}`);
                      const subscribedButton = $(`#subscribed-button${communityAssetId}`);

                      if (subscribeButton.length) {
                          subscribeButton.removeClass('d-none');
                      }

                      if (subscribedButton.length) {
                          subscribedButton.addClass('d-none');
                      }
                  }
              },
          });
      },
      getCommunityAssetSubscriptions(communityAsset) {
        if (!this.asset_data || !communityAsset) {
            return;
        }
        const communityAssetId = communityAsset;

        $.ajax({
          type: "GET",
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/retrieve_community_asset_subscription/`,
          headers: {
              'Authorization': `Token ${this.getToken()}`,
          },
          data: { community_asset_id: communityAssetId },
          success: (response) => {
              if (response.status === "success") {
                const subscriptions = response.data;

                const userId = this.get_user_id();
                const isSubscribed = subscriptions.some(sub => sub.user.id === parseInt(userId, 10));
                this.asset_data.subscribed = isSubscribed;

                const subscribeButton = $(`#subscribe-button${communityAssetId}`);
                const subscribedButton = $(`#subscribed-button${communityAssetId}`);

                if (subscribeButton.length) {
                    subscribeButton.toggleClass('d-none', isSubscribed);
                }
                if (subscribedButton) {
                    subscribedButton.toggleClass('d-none', !isSubscribed);
                }
            }            
          },
      });
      }
      }
    }
