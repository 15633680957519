import { clear_token } from "@/router";

export default {
    mounted() {
      this.initSidebar();
    },
    
    methods: {
      initSidebar() {
        let arrows = document.querySelectorAll(".arrow");
        arrows.forEach(arrow => {
          arrow.addEventListener("click", (e) => {
            let arrowParent = e.target.parentElement.parentElement;
            arrowParent.classList.toggle("show-menu");
          });
        });
      },
      log_out(){
        clear_token()
        this.$router.push({ path:'/',});
      }
    }
  }