export default {
    computed: {
        isHomePage() {
          return this.$route.name === 'home'; // Assuming your home page route is named 'home'
        },
        isPortalPage() {
          return this.$route.name === 'home_filter'; 
        },
        is_group_page() {
          return this.$route.name === 'view_group'; 
        },
      },
}